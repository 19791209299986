// Unsubscribe.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../unsubscribe.css';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://server.sakazidevs.com/api/unsubscribe', { email });
      if (response.data.success) {
        toast.success('Successfully unsubscribed!');
      } else {
        toast.error('Failed to unsubscribe. Please try again later.');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <>
    <div className="unsubscribe-form">
      <h2>Unsubscribe from Sakazi Devs Newsletter!</h2>
      <p>You will no longer receive newletters and updates emails from sakazi devs. Are you sure you want to unsubscribe?</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Unsubscribe</button>
      </form>
    </div>
    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
  </>
  );
};

export default Unsubscribe;