import React, {useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../services.css';
import graphicImage1 from '../media/graphic-image-1.jpg'
import graphicImage2 from '../media/graphic-image-2.jpg'
import graphicImage3 from '../media/graphic-image-3.jpg'
import graphicImage4 from '../media/graphic-image-4.jpg'
import graphicImage5 from '../media/graphic-image-5.jpg'
import graphicImage6 from '../media/graphic-image-6.jpg'
import graphicImage7 from '../media/graphic-image-7.jpg'
import graphicImage8 from '../media/graphic-image-8.jpg'
import graphicImage9 from '../media/graphic-image-9.jpg'
import graphicImage10 from '../media/graphic-image-10.jpg'
import graphicImage11 from '../media/graphic-image-11.jpg'
import graphicImage12 from '../media/graphic-image-12.jpg'
import graphicImage13 from '../media/graphic-image-13.jpg'
import graphicImage14 from '../media/graphic-image-14.jpg'
import graphicImage15 from '../media/graphic-image-15.jpg'


import ImageProject1 from '../media/home-project-1.png';
import ImageProject2 from '../media/home-project-2.png';


function GraphicDesign() {

// code for metadata
  useEffect(() => {
    document.title = "Graphic Design";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'We leverage cutting-edge graphic design technologies to deliver exceptional designs that not only captivate your audience but also elevate your brand identity!');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, terms, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  

  return (
  <>
  {/* section 1 */}
  <section className='service-container'>
  <div class="image-container">
            <div class="image-scroll">
                {/* Images for scrolling */}
                <img src={graphicImage1} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage5} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage8} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage2} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage11} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage7} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage12} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage6} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage14} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage9} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage4} alt="Sakazi Devs" loading='lazy'/>
            </div>
        </div>
    <div className='text-container'>
    <h1> Expert Graphic Design Services Online </h1>
    <p>We leverage cutting-edge graphic design technologies to deliver <span>exceptional designs that not only captivate your audience</span> but also elevate your brand identity!
    </p>
    </div>
  </section>


{/* Section 2 */}
<section className='service-container-1'>
    <div class="column-1">
        <h1>
            Innovative Designs Tailored to Your Vision
        </h1>
    </div>
    <div class="column-2">
        <p>
            Graphic design is the cornerstone of your brand's visual identity: it should be captivating, memorable, and resonate with your audience.<br /><br />
            At Sakazi Designs, we take pride in crafting bespoke designs that align with your brand's essence, allowing your business to stand out amidst the competition. Guided by your vision, our design team will develop unique elements to bring your ideas to life.<br /><br />
            Utilizing the latest graphic design techniques, we'll create visually stunning artworks and branding elements that leave a lasting impression on your audience.<br /><br />
            The result is a custom-designed visual identity that's compelling, cohesive, and poised to elevate your brand presence.
        </p>
    </div>
</section>

{/* Section 3 */}
<section className="service-container-2">
    <div class="column-1">
        <h1>What Sets Us Apart</h1>
    </div>
    <div class="column-2">
        <div class="card">
            <h2>Creative</h2>
            <p>We're artists at heart, and it reflects in our designs. Your new branding will be meticulously crafted to deliver unique visuals that defy industry norms.</p>
        </div>
        <div class="card">
            <h2>Strategic</h2>
            <p>We draw on insights from client consultations to make strategic decisions on design elements, ensuring every visual aligns with your brand's objectives.</p>
        </div>
        <div class="card">
            <h2>Intuitive</h2>
            <p>Our designers blend creativity with a deep understanding of user preferences to deliver intuitive and engaging visual experiences.</p>
        </div>
        <div class="card">
            <h2>Impactful</h2>
            <p>Your brand's visual identity is crucial. We ensure every design element contributes to a cohesive brand narrative that resonates with your target audience.</p>
        </div>
    </div>
</section>

{/* Section 4 */}
<section className="service-container-3">
    <div class="column-1">
        <div class="image-container">
            <img src={graphicImage3} alt="Sakazi Graphic Designs" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Thoughtful <br />Graphic Design</h1>
            <p>Prior to diving into visuals, our team meticulously analyzes your brand's goals, strategy, and target audience. This ensures that every design element serves a purpose and contributes to your brand's success.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>Eye-catching <br />Branding</h1>
            <p>Starting with conceptual sketches, we craft visually stunning branding assets that resonate with your audience and effectively communicate your brand's story and values.</p>
        </div>
        <div class="image-container">
            <img src={graphicImage10} alt="Sakazi Branding" loading='lazy'/>
        </div>
    </div>

    <div class="column-1">
        <div class="image-container">
            <img src={graphicImage13} alt="Sakazi Illustrations" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Illustrations & <br />Iconography</h1>
            <p>Our creative graphic designers enhance your brand's identity through custom illustrations and thoughtful use of iconography, guiding users and sparking meaningful interactions.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>And More...</h1>
            <p>We design every element with versatility in mind, ensuring your branding looks exceptional across various mediums. We welcome feedback and iterate until your brand's visuals exceed expectations.</p>
        </div>
        <div class="image-container">
            <img src={graphicImage15} alt="Sakazi Graphic Design Process" loading='lazy'/>
        </div>
    </div>
    <section class="wave-container">
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        <div class="wave wave4"></div>
    </section>
</section>


{/* service-container-4 section */}
<section className="service-container-4">
  <div class="column-1">
    <div class="text-h">
      <h2>Graphic Design <br />Workflow</h2>
    </div>
    <div class="text-p">
      <p>This shows the stages of our typical graphic design process, from the initial consultation all the way to final delivery. Our designers will collaborate with you at every step to ensure your vision is brought to life.</p>
    </div>
  </div>
  <div class="steps">Steps involved</div>
  <div class="column-2">

    {/* drop-containers */}
    <div class="drop" style={{ '--crl': 'red' }}>
      <div class="drop-content">
        <h4>Step 1</h4>
        <h2>We gather and understand your design requirements</h2>
      </div>
    </div>

    <div class="drop" style={{ '--crl': 'purple' }}>
      <div class="drop-content">
        <h4>Step 2</h4>
        <h2>We create initial design concepts</h2>
      </div>
    </div>
    <div class="drop" style={{ '--crl': 'blue' }}>
      <div class="drop-content">
        <h4>Step 3</h4>
        <h2>We refine the designs based on your feedback</h2>
      </div>
    </div>

    <div class="drop" style={{ '--crl': 'green' }}>
      <div class="drop-content">
        <h4>Step 4</h4>
        <h2>Finalize designs and deliver completed project</h2>
      </div>
    </div>
  </div>
</section>


{/* service-container-5 section */}
<section className='service-container-5'>
        <div class="column-1">
            <h1>Featured <br />Projects</h1>
            <div class="img-container">
                <img src={ImageProject2} alt="Sakazi Devs Featured Projects" loading='lazy'/>
                <div class="text-content">
                    <h2>Learntechpress</h2>
                    <p>We crafted and developed learning management system for Learntechpress learning community!</p>
                </div>
            </div>
        </div>
    <div class="column-2">
        <div class="img-container">
            <img src={ImageProject1} alt="Sakazi Devs Featured Projects" loading='lazy'/>
            <div class="text-content">
                <h2>Wendo News</h2>
                <p>We crafted a very beautiful website design for wendo news company</p>
            </div>
        </div>
    </div>
</section>



{/* service container 6 */}
<section className='service-container-6'>
<div className='text-container'>
    <h1>Get Started with Our Expert Graphic Design Services</h1>
    <p>We utilize modern design techniques to create visually stunning graphics that elevate your brand's identity. Our graphic design services cover a wide range of needs, including logo design, branding materials, print materials (flyers, brochures, posters), illustrations, infographics, and more.</p>
    <Link to="/quote">Start your project</Link>
</div>

    <div class="image-container">
              <div class="image-scroll">
                  {/* Images for scrolling */}
                  <img src={graphicImage1} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage5} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage8} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage2} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage11} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage7} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage12} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage6} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage14} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage9} alt="Sakazi Devs" loading='lazy'/>
                <img src={graphicImage4} alt="Sakazi Devs" loading='lazy'/>
              </div>
          </div>
    </section>


    {/* service container 7 section */}
    <section className='service-container-7'>

    </section>
  </>
  );
}

export default GraphicDesign;