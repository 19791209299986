import '../home.css';
import axios from 'axios';
import React, { useState, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faShare } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import ScrollRevealComponent from './ScrollRevealComponent';

// importing images
import Imageservices1 from '../media/services-web-1.png';
import Imageservices3 from '../media/services-web-2.jpg';
import Imageservices2 from '../media/services-web-3.jpg';
import Imageservices4 from '../media/services-web-4.jpg';
import ImageAbout1 from '../media/home-about-img1.png';
import ImageAbout2 from '../media/home-about-img2.jpg';
import ImageProject1 from '../media/home-project-1.png';
import ImageProject2 from '../media/home-project-2.png';
import HeroVideo from '../media/hero-section-bg.mp4';

// Brands
import Brand1 from '../media/logo1.png';
import Brand2 from '../media/brand2.png';
import Brand3 from '../media/brand3.png';
import Brand4 from '../media/brand4.png';



  // our projects counters code
  const Counter = ({ max }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timeoutId;
    const incrementCounter = () => {
      setCount((prevCount) => {
        if (prevCount === max) {
          return 0; 
        } else {
          return prevCount + 1;
        }
      });

      timeoutId = setTimeout(incrementCounter, 200);
    };

    incrementCounter();

    return () => clearTimeout(timeoutId);
  }, [max]);

  return <span className="counter">{count}+</span>;
};

const Section = ({ title, max }) => {
  return (
    <div className="section">
      <h2>{title}</h2>
      <Counter max={max} />
    </div>
  );
};


function Home() {
  const [visibleServices, setVisibleServices] = useState([]);

  // submitting review code
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await axios.get('https://server.sakazidevs.com/api/reviews');
      const lastSixReviews = response.data.reviews.slice(-6); 
      setReviews(lastSixReviews);
      calculateAverageRating(lastSixReviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  const calculateAverageRating = (reviewsData) => {
    if (reviewsData.length === 0) {
      setAverageRating(0);
      return;
    }

    const totalRating = reviewsData.reduce((acc, curr) => acc + curr.rating, 0);
    setAverageRating(totalRating / reviewsData.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, rating, company, reviewText } = e.target.elements;
    if (name.value && rating.value && reviewText.value) {
      try {
        const response = await axios.post('https://server.sakazidevs.com/api/reviews', {
          name: name.value,
          rating: rating.value,
          company: company.value || '',
          reviewText: reviewText.value,
        });
        console.log(response.data.message);
        fetchReviews(); 
        // Clear form fields
        name.value = '';
        rating.value = '';
        company.value = '';
        reviewText.value = '';
        // Hide the form after submission
        setShowForm(false);
      } catch (error) {
        console.error('Error submitting review:', error);
      }
    } else {
      alert('Please enter name, rating, and review text');
    }
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<span key={i} className={i < Math.round(averageRating) ? 'star-filled' : 'star-empty'}>&#9733;</span>);
    }
    return stars;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  //submitting review code ends here

  useEffect(() => {
    const handleScroll = () => {
      const serviceSections = document.querySelectorAll('.service-box');
      const windowHeight = window.innerHeight;

      serviceSections.forEach((serviceSection, index) => {
        const serviceSectionTop = serviceSection.getBoundingClientRect().top;

        if (serviceSectionTop < windowHeight / 2) {
          setVisibleServices(prevState => {
            if (!prevState.includes(index)) {
              return [...prevState, index];
            }
            return prevState;
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

// the code to change the projects worked on
const [currentSlide, setCurrentSlide] = useState(0);

useEffect(() => {
    const interval = setInterval(() => {
        setCurrentSlide(prevSlide => (prevSlide + 1) % 3);
    }, 4000);

    return () => clearInterval(interval);
}, []);

// code to handle opening an functionalities of the schedule meeting form
const [isModalOpen, setIsModalOpen] = useState(false);
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phoneNumber, setPhoneNumber] = useState('');
const [date, setDate] = useState('');
const [time, setTime] = useState('');
const [disabledTimes, setDisabledTimes] = useState([]);
const [notification, setNotification] = useState('');

const timeOptions = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];

useEffect(() => {
  if (date !== '') {
    const currentDate = new Date().toISOString().split('T')[0];
    if (date < currentDate) {
      // Prevent selecting past dates
      setNotification('Please select a future date.');
      return;
    }

    fetch(`https://server.sakazidevs.com/get-disabled-times?date=${date}`)
      .then(response => response.json())
      .then(data => {
        setDisabledTimes(data);
        setNotification('');
      })
      .catch(error => console.error('Error fetching disabled times:', error));
  }
}, [date]);

const handleScheduleMeeting = () => {
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
  setNotification('');
};

const handleSubmitMeetingForm = (e) => {
  e.preventDefault();
  fetch('https://server.sakazidevs.com/schedule-meeting', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, email, phoneNumber, date, time }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        setNotification(data.error);
      } else if (data.message) {
        setNotification(data.message);
        setTimeout(() => {
          setName('');
          setEmail('');
          setPhoneNumber('');
          setDate('');
          setTime('');
          handleCloseModal(); 
        }, 3000);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};
// code to handle opening and functionalities of the schedule meeting form ends here


// code to calculate time left for discounted services
const calculateTimeLeft = () => {
  const difference = +new Date("2024-06-01") - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      d: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
      mins: Math.floor((difference / 1000 / 60) % 60),
      secs: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
};

const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

useEffect(() => {
  const timer = setTimeout(() => {
    setTimeLeft(calculateTimeLeft());
  }, 1000);

  return () => clearTimeout(timer);
});

const timerComponents = [];

Object.keys(timeLeft).forEach((interval) => {
  if (!timeLeft[interval]) {
    return;
  }

  timerComponents.push(
    <span key={interval}>
      {timeLeft[interval]} {interval}{" "}
    </span>
  );
});

//the code for timeleft ends here

//code for scroll reveal
const headingConfig = {
  origin: 'bottom',
  distance: '20px',
  duration: 1000,
  delay: 200,
  easing: 'ease',
};

const containerConfig = {
  origin: 'bottom',
  distance: '20px',
  duration: 1000,
  delay: 400,
  easing: 'ease',
};
//scroll reveal ends here



// code for metadata
useEffect(() => {
  document.title = "Sakazi Devs - Digital and optimized IT services providers";

  const metaDescription = document.createElement('meta');
  metaDescription.setAttribute('name', 'description');
  metaDescription.setAttribute('content', 'Sakazi Devs is a digital services providers in Kenya. We offer solutions in Web design, graphic design, automation, UI & UX Design, Ai Integration, CMS, Consultancy among others. With a diverse blend of talents and backgrounds, we bring creativity, expertise, and a fresh perspective to every project we undertake.');
  document.head.appendChild(metaDescription);


  const metaKeywords = document.createElement('meta');
  metaKeywords.setAttribute('name', 'keywords');
  metaKeywords.setAttribute('content', 'contact us, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
  document.head.appendChild(metaKeywords);


  return () => {
    document.title = "";

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  };
}, []);



// code for blogs section
const blogs = [
  {
    id: 1,
    title: 'Top Trends in Website Development for 2024',
    imageUrl: 'https://assets-global.website-files.com/5e052922ed2a27237b60d8de/6563fc1b5b72fd519bb51fb2_Thumbnail%20Direction%20%231.jpg',
    description: 'Discover the latest trends in website development to stay ahead in 2022 and create modern, user-friendly websites',
    url: 'https://sakazidevs.com/blogs/top-trends-in-website-development-for-2024',
  },
  {
    id: 2,
    title: 'The Importance of Responsive Web Design for Mobile Users',
    imageUrl: 'https://www.proglobalbusinesssolutions.com/wp-content/uploads/2018/05/Responsive-Web-design.png',
    description: 'Explore why responsive web design is crucial for engaging mobile users and optimizing user experience on all devices.',
    url: 'https://sakazidevs.com/blogs/the-importance-of-responsive-web-design-for-mobile-users',
  },
  {
    id: 3,
    title: 'Enhancing Cybersecurity Measures for Small Businesses',
    imageUrl: 'https://cdn.elearningindustry.com/wp-content/uploads/2022/12/shutterstock_2037142181.jpg',
    description: 'Learn how to strengthen cybersecurity for small businesses with effective measures and protect sensitive data from cyber threats.',
    url: 'https://sakazidevs.com/blogs/enhancing-cybersecurity-measures-for-small-businesses',
  },
];

const goToBlogPage = (url) => {
  // Redirect to the respective blog page
  window.location.href = url;
};

const shareBlog = async (blogId) => {
  const blog = blogs.find(blog => blog.id === blogId);
  if (!navigator.share) {
    alert('Sharing is not supported in your browser.');
    return;
  }

  try {
    await navigator.share({
      title: blog.title,
      text: blog.description,
      url: window.location.href,
    });
    console.log('Shared blog:', blog);
  } catch (error) {
    console.error('Error sharing blog:', error);
  }
};
//blogs section code ends here


  return (
    <>
      <div className='home'>
      <video autoPlay loop muted className="background-video">
        <source src={HeroVideo} type="video/mp4" />
      </video>
      <div className='background-overlay'></div>
        <div className='home-content'>
          <div className='home-text'>
          <h5>Sakazi Devs helps you</h5>
          <ScrollRevealComponent config={headingConfig}>
          <h1>Transform your digital <span>landscape</span></h1>
          </ScrollRevealComponent>

          <p>From captivating web design, graphic design to cutting-edge AI integration, we offer comprehensive solutions to meet all your digital needs.</p>
          <a href='#ourservices'><button>Our Services</button></a>
          <button onClick={handleScheduleMeeting} className="schedule-btn">Schedule Meeting</button>
          </div>
          
          <div className="countdown-container">
            <div className='text'>
              <h3>Limited time offer!</h3>
              <p>Get 10% Discount on All Services!</p>
            </div>
            <div className='counter-timer'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
              {timerComponents}</div><br /> <br />

            <div className="average-rating">
            <p>Average Rating: {averageRating.toFixed(1)}</p>
            <div className="stars">
              {[...Array(5)].map((_, index) => (
                <span key={index} className={index < Math.round(averageRating) ? 'star-filled' : 'star-empty'}>&#9733;</span>
              ))}
            </div>
      </div>
          </div>
        </div>

        <div className='curved-bg'>
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 1200 120"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: '#ffffff', width: '100%', height: 70, transform: 'rotate(180deg)' }}
          >
            <path
              d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
              opacity=".25"
            />
            <path
              d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
              opacity=".5"
            />
            <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
          </svg>
        </div>
      </div>


{/* Home about section */}
<div className="about-us-container">
      <div className="about-us-content">
        <div className="about-us-intro">
          <h1>About Sakazi Devs</h1>
          <p>Digital and optimized IT services providers</p>
        </div>
        <div className="about-us-description">
          <div className="description-left">
            <img src={ImageAbout1} alt="Team" className="about-us-image" />
            <span className='my-3d-text'>Who Are We?</span>
            <p>
              We are a dynamic team of dreamers, thinkers, and doers, united by a shared vision to revolutionize the IT services industry. With a diverse blend of talents and backgrounds, we bring creativity, expertise, and a fresh perspective to every project we undertake.
            </p>
          </div>
          <div className="description-right">
          <img src={ImageAbout2} alt="Team" className="about-us-image" />
          <span className='my-3d-text'>What Drives Us?</span>
            <p>
              Innovation is our lifeblood. We thrive on pushing boundaries, challenging conventions, and exploring the uncharted territories of possibility. Every day, we strive to inspire, create, and deliver solutions that not only meet but exceed expectations.
            </p>
          </div>
        </div>
        <div className="about-us-promise">
        <div className='text'>
        <span className='my-3d-text'>Our Promise:</span><i class="fa-solid fa-quote-right"></i>
          <p>
            When you choose Sakazi Devs, you're not just choosing a service provider – you're choosing a collaborative ally dedicated to bringing your dreams to life. We promise to listen, to innovate, and to exceed your expectations at every turn.
          </p>
          </div>

          <Link to='/about'><button>Learn more</button></Link>
        </div>
      </div>
    </div>


      {/* Services Sections */}
      <section className='home-services' id='ourservices'>
        <h1>Our Services</h1>
        <h5>What we offer</h5>
        <div className='services-container'>
          {/* service one */}
          <div className={`service-box ${visibleServices.includes(0) ? 'show' : ''}`}>
          <div className='services-content'>
              <h2>Web Design</h2>
              <p>Our expert web development team creates visually stunning and highly functional websites and web applications that captivate your audience and enhance your online presence. We utilize the latest web technologies and follow industry best practices to deliver responsive, user-friendly, and SEO-optimized websites.</p><br /> <br />
              <Link to="/services/web-development"><button>Learn more</button></Link>
            </div>
            <div className='services-image'>
              <img src={Imageservices1} alt="Service" />
            </div>
          </div>

          {/* service two */}
          <div className={`service-box ${visibleServices.includes(1) ? 'show' : ''}`}>
          <div className='services-content'>
              <h2>Graphic Design</h2>
              <p>Our expert graphic design team creates visually appealing graphics and illustrations that resonate with your audience and convey your brand message effectively. From logos and branding materials to marketing collateral and digital assets, we deliver designs that leave a lasting impression.</p><br /> <br />
              <Link to="/services/graphic-design"><button>Learn more</button></Link> 
            </div>
            <div className='services-image'>
              <img src={Imageservices2} alt="Service" />
            </div>
          </div>

          {/* service three */}
          <div className={`service-box ${visibleServices.includes(2) ? 'show' : ''}`}>
          <div className='services-content'>
              <h2>UI & UX Design</h2>
              <p>Our UI/UX design team specializes in creating intuitive and engaging user interfaces that elevate user experiences. We conduct in-depth user research, create wireframes and prototypes, and implement user-centric design principles to deliver interfaces that are not only visually appealing but also functional and easy to use.</p><br /> <br />
              <Link to="/services/ui-&-ux-design"><button>Learn more</button></Link> 
            </div>
            <div className='services-image'>
              <img src={Imageservices3} alt="Service" />
            </div>
          </div>

          {/* service four */}
          <div className={`service-box ${visibleServices.includes(3) ? 'show' : ''}`}>
          <div className='services-content'>
              <h2>SEO</h2>
              <p>Our SEO experts optimize your website to improve its visibility and ranking on search engine results pages (SERPs). We conduct comprehensive keyword research, optimize on-page and off-page elements, and monitor performance to ensure your website attracts organic traffic and generates leads.</p> <br /> <br />
             <Link to="/services/search-engine-optimization"><button>Learn more</button></Link> 
            </div>
            <div className='services-image'>
              <img src={Imageservices4} alt="Service" />
            </div>
          </div>

        </div>
        <div className='all-services-btn'>
        <Link to="/services"><button>See all services</button></Link>
        </div>
      </section>




{/* Our brands worked on section */}

<section className="brands-section">
      <h1>Trusted by</h1>
      <h5>Top Brands</h5>
      <div className="brand-logos">
        <img src={Brand1} alt="Brand 1" />
        <img src={Brand2} alt="Brand 2" />
        <img src={Brand3} alt="Brand 3" />
        <img src={Brand4} alt="Brand 4" />
      </div>
    </section>

<br />

      {/* Latest Project section */}
      <div className="latest-projects">
            <div className="project-container">
                <ProjectSlide isActive={currentSlide === 0} className="Image-container">
                    <img src={ImageProject1} alt="Project 1" />
                    <div className="project-details">
                        <h3>Wendo News</h3>
                        <p>We crafted and developed news and media website for wendo news!</p>
                        <Link to="https://www.wendonews.com">Learn More</Link>
                    </div>
                </ProjectSlide>
                <ProjectSlide isActive={currentSlide === 1}>
                    <img src={ImageProject2} alt="Project 2" />
                    <div className="project-details">
                        <h3>Learntechpress</h3>
                        <p>We designed the learning management systems for learnpress</p>
                        <Link href="https://www.learntechpress.com">Learn More</Link>
                    </div>
                </ProjectSlide>
                <ProjectSlide isActive={currentSlide === 2}>
                    <img src={ImageProject2} alt="Project 2" />
                    <div className="project-details">
                        <h3>Learntechpress</h3>
                        <p>We designed the learning management systems for learnpress</p>
                        <Link href="https://www.learntechpress.com">Learn More</Link>
                    </div>
                </ProjectSlide>
            </div>

            <div className="work-info">
                <h1>Our Work</h1>
                <p>See our latest projects</p>
            </div>
        </div>


{/* Counter section */}
      <div className="app">
        <Section title="Projects Completed" max={30} />
        <Section title="Happy Clients" max={25} />
        <Section title="Proposed Projects" max={10} />
        <Section title="Years of Experience" max={6} />
      </div>



{/* Why people are choosing us section */}
<section className='why-people-choose-us'>
  <div className='column-1'>
  <h1>Why most people consider to work with us?</h1>
  <p>At Sakazi devs, we strive to deliver top-notch tech services tailored to your needs. Here's why clients continue to choose us</p>
  <a href='#reviews'>Explore more</a>
  </div>

<div className='column-2'>
<div className='column-format'>
<div className='box'>
 <h3>Expertise</h3>
 <p>Experienced professionals in web design, graphic design, UI/UX design, SEO.</p>
</div>
<div className='box'>
<h3>Quality</h3>
<p>Delivering high-quality services that exceed expectations, ensuring client satisfaction</p>
</div>
</div>
<div className='box'>
<h3>Customer-Centric</h3>
<p>Putting our clients first, understanding their needs, and providing personalized solutions.</p>
</div>
</div>
</section>




{/* blogs section */}
<section className='our-blogs-section-cl'>
      <h1>Check our Blogs</h1>
      <p>Be equipped with something new!</p>
      <div className="blog-section">
        {blogs.map((blog) => (
          <div className="blog-container" key={blog.id}>
            <h2 onClick={() => goToBlogPage(blog.url)}>{blog.title}</h2>
            <img src={blog.imageUrl} alt={blog.title} />
            <p>{blog.description}</p>
            <div className="share-icon" onClick={() => shareBlog(blog.id)}>
              <FontAwesomeIcon icon={faShare} />
            </div>
          </div>
        ))}
      </div>
    </section>


      {/* Testimonials Section */}
      <div className="testimonial-container" id='reviews'>
      <h1>Latest Reviews</h1>
      <p>Average Rating: {averageRating.toFixed(1)}</p>
      <div className="stars">
        {renderStars()}
      </div>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <p><strong>{review.name}</strong></p>
            <p style={{ color: "#777" }}><i>{review.company}</i></p>
            <p><FontAwesomeIcon icon={faQuoteLeft} size="lg" style={{ color: "#777" }}/>    {review.reviewText}</p>
          </div>
        ))}
      </Slider><br />
      <p>What can you say about services offered by Sakazi Devs?</p>
      <button onClick={() => setShowForm(true)}>Submit Review</button>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Name" required /><br />
          <input type="text" name="company" placeholder="Company/Business (Optional)" /><br />
          <textarea name="reviewText" placeholder="Write your review..." required></textarea><br />
          <select name="rating" required>
            <option value="">Select Rating</option>
            {[1, 2, 3, 4, 5].map((rating) => (
              <option key={rating} value={rating}>{rating}</option>
            ))}
          </select><br />
          <button type="submit">Submit</button>
          <button type="cancel" onClick={() => setShowForm(false)}>Cancel</button>
        </form>
      )}
    </div>


{/* code to open the schedule meeting form */}
<div className="schedule-meeting-container">
      {isModalOpen && (
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <form onSubmit={handleSubmitMeetingForm}>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
              <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
              <input type="date" value={date} onChange={(e) => setDate(e.target.value)} min={new Date().toISOString().split('T')[0]} />
              <select value={time} onChange={(e) => setTime(e.target.value)}>
                <option value="">Select Time</option>
                {timeOptions.map(option => (
                  <option 
                    key={option} 
                    value={option} 
                    disabled={disabledTimes.includes(option)}
                    style={{ fontWeight: disabledTimes.includes(option) ? 'normal' : 'bold', color: disabledTimes.includes(option) ? '#999' : '#000' }}
                  >
                    {option}
                  </option>
                ))}
              </select>
              <button type="submit" className="submit-btn">Submit</button>
              {notification && <p className={notification.includes('successfully') ? 'success' : 'error'}>{notification}</p>}
            </form>
          </div>
        </div>
      )}
    </div>

    {/*  */}
    </>
  );
}

// another code for changin the project worked on
const ProjectSlide = ({ isActive, children }) => {
  return (
      <div className={`project-slide ${isActive ? 'active' : ''}`}>
          {children}
      </div>
  );
};

export default Home;
