import '../App.css';
import { useEffect } from 'react';

function Terms() {


  // code for metadata
  useEffect(() => {
    document.title = "Terms and conditions";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'y accessing or using any of our offerings, you agree to comply with and be bound by these Terms. Please read them carefully. If you do not agree with any part of these Terms, you may not access or use our products, services, or website.');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, terms, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  
  return (
 <>
 <div className='policies-container'>
 <h1>Terms and Conditions</h1>
<div className='policy-box'>
<p>
Last Updated: <span>4th April, 2024</span><br/><br />

Welcome to Sakazi Devs Company! These Terms and Conditions ("Terms") govern your 
use of our products, services, and website. By accessing or using any of our offerings, 
you agree to comply with and be bound by these Terms. Please read them carefully. 
If you do not agree with any part of these Terms, you may not access or use our products, 
services, or website.
</p>
<h2>1. Acceptance of Terms</h2>
<p>
  <ul>
    <li>By using our products, services, or website, you represent and warrant that you
       have read, understood, and agree to be bound by these Terms.</li>
    <li>You must be at least 18 years old to access or use our products and services. 
      By using our products and services, you represent and warrant that you are of legal age to form a binding contract with Sakazi devs Company.</li>
  </ul>
</p>
<h2>2. Use of Our Products and Services</h2>
<p>
  <ul>
    <li>You agree to use our products and services only for lawful purposes and in compliance with all applicable laws, regulations, and third-party agreements.</li>
    <li>You may not use our products and services in any manner that could damage, disable, overburden, or impair our servers or networks, 
      or interfere with any other party's use and enjoyment of our products and services.</li>
    <li>You acknowledge and agree that your use of our products and services may be subject to additional terms and conditions, which will be provided to you separately.</li>
  </ul>
</p>
<h2>3. Intellectual Property Rights</h2>
<p>
  <ul>
    <li>All content, materials, and intellectual property rights associated with our products, services, and website are owned or licensed by Sakazi devs Company. 
      You may not use, reproduce, distribute, or create derivative works based on our content without our prior written consent.</li>
      <li> You retain ownership of any content or materials you submit or upload to our website or services. By submitting or uploading content, you grant 
        Sakazi devs Company a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, 
        and display such content for the purpose of providing and promoting our products and services.</li>
  </ul>
</p>
<h2>4. Payments and Billing</h2>
<p>
  <ul>
    <li> Payment for our products and services is due upon purchase unless otherwise agreed upon in writing. Prices are subject to change without notice.</li>
    <li>You agree to provide accurate and complete billing information and to promptly update such information as necessary to ensure timely payment of fees.</li>
    <li>Payments made to Sakazi devs Company are non-refundable, except as required by applicable law or as otherwise stated in our refund policy.</li>
  </ul>
</p>
<h2>5. Limitation of Liability</h2>
<p>
  <ul>
    <li>To the extent permitted by law, Sakazi devs Company and its affiliates, directors, officers, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive 
      damages arising out of or in connection with your use of our products, services, or website.</li>
    <li>In no event shall Sakazi devs Company's total liability to you for all damages exceed the amount paid by you, if any, for accessing or using our products, services, or website.</li>
  </ul>
</p>
<h2>6. Indemnification</h2>
<p>You agree to indemnify, defend, and hold harmless Sakazi devs Company and its affiliates, directors, officers, employees, agents, and licensors 
  from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with your breach of these Terms or your use of our products, services, or website.</p>
<h2>7. Modifications to Terms</h2>
<p>We reserve the right to update or modify these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting on our website. Your continued use of our products, services, 
  or website after the posting of updated Terms constitutes your acceptance of such changes.</p>
<h2>8. Governing Law and Jurisdiction</h2>
<p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in [Jurisdiction].</p>
<h2>9. Entire Agreement</h2>
<p>These Terms constitute the entire agreement between you and Sakazi devs Company regarding your use of our products, services, and website, and supersede any prior agreements or understandings, whether written or oral.</p>
<h2>10. Contact Us</h2>
<p>
If you have any questions, concerns, or requests regarding this terms, please contact us at <b>info@sakazidevs.com</b> or <b>+254746092415</b>.
</p>
</div>
</div>
</>
  );
}
export default Terms;