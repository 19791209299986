import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import '../App.css';

const FAQs = () => {
  const [faqs] = useState([
    {
      question: "What services does Sakazi devs offer?",
      answer: "Sakazi devs offers a wide range of IT services including web design, graphic design, UI/UX design, software development, mobile app development, digital marketing, IT consulting, and more."
    },
    {
      question: "How experienced is Sakazi devs in the IT industry?",
      answer: "Sakazi devs has been in the IT industry for over a decade, serving clients with expertise and dedication. Our team consists of seasoned professionals with diverse backgrounds and skills."
    },
    {
      question: "Can Sakazi devs handle projects of all sizes?",
      answer: "Yes, we cater to projects of all sizes, from small businesses to large enterprises. We tailor our services to meet the specific needs and requirements of each client, ensuring satisfactory results every time."
    },
    {
      question: "What industries does Sakazi devs work with?",
      answer: "We work with clients from various industries including but not limited to healthcare, finance, e-commerce, education, real estate, entertainment, and nonprofit organizations."
    },
    {
      question: "How does Sakazi devs ensure the quality of its services?",
      answer: "At Sakazi devs, we prioritize quality in every aspect of our work. We follow industry best practices, employ skilled professionals, conduct thorough testing, and maintain clear communication with clients throughout the project lifecycle."
    },
    {
      question: "Does Sakazi devs provide ongoing support after project completion?",
      answer: "Yes, we offer ongoing support and maintenance services to ensure that our clients' systems and applications run smoothly even after the project is completed. Our support team is available to address any issues and provide assistance whenever needed at an extra cost."
    },
    {
      question: "Can Sakazi devs help improve an existing website or application?",
      answer: "Absolutely, we specialize in redesigning and optimizing existing websites and applications to enhance their performance, usability, and visual appeal. Whether it's a minor tweak or a major overhaul, we've got you covered."
    },
    {
      question: "What sets Sakazi devs apart from other IT companies?",
      answer: "Our commitment to excellence, personalized approach, innovative solutions, and exceptional customer service set us apart from the competition. We go above and beyond to exceed our clients' expectations and deliver outstanding results."
    },
    {
      question: "How does Sakazi devs handle data security and confidentiality?",
      answer: "We take data security and confidentiality very seriously. We implement robust security measures to safeguard our clients' sensitive information and adhere to strict confidentiality agreements to protect their privacy."
    },
    {
      question: "Does Sakazi devs offer customized solutions?",
      answer: "Yes, we specialize in providing customized solutions tailored to the unique needs and requirements of each client. Whether you need a custom website, application, or digital marketing strategy, we'll work closely with you to bring your vision to life."
    },
    {
      question: "What is the typical timeline for completing a project with Sakazi devs?",
      answer: "The timeline for completing a project depends on its scope, complexity, and specific requirements. We work closely with our clients to establish realistic timelines and milestones and strive to deliver projects on time and within budget."
    },
    {
      question: "How can I request a quote from Sakazi devs?",
      answer: "You can request a quote from Sakazi devs by filling out the contact form on our website or by contacting us directly via phone or email. We'll be happy to discuss your project needs and provide you with a detailed quote."
    },
    {
      question: "Does Sakazi devs provide training or documentation for its solutions?",
      answer: "Yes, we provide training and documentation for our solutions to ensure that our clients can effectively use and manage their websites, applications, and digital tools. Our team is also available to provide ongoing support and guidance as needed."
    },
    {
      question: "Is Sakazi devs involved in community or social responsibility initiatives?",
      answer: "Yes, at Sakazi devs, we believe in giving back to the community and are actively involved in various social responsibility initiatives. We support local charities, participate in environmental sustainability efforts, and strive to make a positive impact wherever we can."
    },
    {
      question: "How can I get in touch with Sakazi devs for further inquiries?",
      answer: "You can get in touch with Sakazi devs by visiting our website and filling out the contact form, or by reaching out to us via phone or email. Our friendly team members will be happy to assist you with any further inquiries or concerns you may have."
    }
  ]);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // code for ad section
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };


  // code for metadata
  useEffect(() => {
    document.title = "Frequently Asked Questions";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Get answers to what most people are asking about Sakazi Devs');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, frequently asked questions, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);
  

  return (
    <>
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div className="faq-item" key={index}>
          <div className="question-container" onClick={() => toggleAccordion(index)}>
            <div className="question">{faq.question}</div>
            <FontAwesomeIcon icon={openIndex === index ? faAngleUp : faAngleDown} className="icon" />
          </div>
          <div className={`answer ${openIndex === index ? 'open' : ''}`}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>

  <br/>
      {/* promoted ads */}
<div className={`ad-section ${isClosed ? 'closed' : ''}`}>
      <div className="ad-header">
        <p className="ad-text">Ad</p>
        <button className="close-button" onClick={handleClose}>X</button>
      </div>
      <div className="promo-content">
        <h2>Ready to Host Your Website?</h2>
        <p>Are you looking for <b>reliable</b>, <b>stable</b>, <b>affordable</b> place to get hosting or purchase your domain for your website or web application? Hostpinnacle gets you covered, starting from 3,499 KSH yearly. Use our <b>Promolink!</b></p>
        <Link to="https://www.hostpinnacle.co.ke/clients/aff.php?aff=2063" target="_blank" rel="noopener noreferrer" className="promo-button">Get Started</Link>
      </div>
    </div>
  </>
  );
};

export default FAQs;