import React, { useState, useEffect } from 'react';
import '../custom.css';

 
const CareersSection = () => {
  const careersData = [
    {
      title: "Web Developer",
      qualifications: "Bachelor's degree in Computer Science or related field. Proficiency in JavaScript, React, and Node.js.",
      requirements: "Minimum of 2 years of professional experience. Strong problem-solving skills.",
      sendUsEmail: "You can send your cv through careers@sakazidevs.com"
    },
    {
      title: "UX/UI Designer",
      qualifications: "Bachelor's degree in Design or related field. Proficiency in Adobe Creative Suite, Figma.",
      requirements: "Portfolio demonstrating design skills. Understanding of user-centered design principles.",
      sendUsEmail: "You can send your cv through careers@sakazidevs.com"

    },
    // Add more careers as needed
  ];

  const [showDetails, setShowDetails] = useState({});

  const toggleDetails = (index) => {
    setShowDetails({ ...showDetails, [index]: !showDetails[index] });
  };


  // code for metadata
  useEffect(() => {
    document.title = "Careers";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Explore available careers opportunities at Sakazi Devs');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'careers, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <div className="careers-section">
      {careersData.map((career, index) => (
        <div className="career" key={index}>
          <div className="career-header">
            <h3>{career.title}</h3>
            <button onClick={() => toggleDetails(index)}>
              {showDetails[index] ? '-' : '+'}
            </button>
          </div>
    
          {showDetails[index] && (
            <div className="career-details">
              <p><strong>Qualifications:</strong> {career.qualifications}</p>
              <p><strong>Requirements:</strong> {career.requirements}</p>
              <p><strong>How to apply:</strong> {career.sendUsEmail}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CareersSection;
