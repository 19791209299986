import './App.css';
import { Route, Routes, Link } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
// main pages
import Home from './Components/Home';
import Services from './Components/Services';
import About from './Components/About';
import Contact from './Components/Contact';
import Quote from './Components/Quote';
import Careers from './Components/Careers';
import FAQs from './Components/FAQs';
import ReferralProgram from './Components/ReferralProgram';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import ErrorPage from './Components/ErrorPage';
import Unsubscribe from './User/unsubscribe';

// blogs pages
import CybersecurityMeasuresSmallBusinesses from './Blogs/CybersecurityMeasuresSmallBusinesses';
import ResponsiveWebDesignImportance from './Blogs/ResponsiveWebDesignImportance';
import WebsiteDevelopmentTrends2022 from './Blogs/WebsiteDevelopmentTrends2022';


// various services we offer
import WebDevelopment from './Components/web-development';
import GraphicDesign from './Components/Graphic_Design';
import CMS from './Components/Content_Management_Systems';
import UIUXDesign from './Components/ui_and_ux_design';
import SEO from './Components/Search_Engine_Optimization';


// images
import mylogo from './media/mylogo.png';




function App() {
  const [isOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };



  // scroll to top code
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to only run the effect once

  // Function to scroll to top when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };




  // request quote button code
  const [isHovered, setIsHovered] = useState(false);
  const [isVisibleOnScroll, setIsVisibleOnScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisibleOnScroll(true);
      } else {
        setIsVisibleOnScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };



// code to open report problem
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleModalClick = (e) => {
    if (e.target.className === 'modal') {
      closeModal();
    }
  };

// code to handle subscribe button
const [email, setEmail] = useState('');
const [subscribed, setSubscribed] = useState(false);

const handleSubscribe = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://server.sakazidevs.com/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    });
    const data = await response.json();
    if (data.message === 'Subscribed successfully') {
      setSubscribed(true);
    }
  } catch (error) {
    console.error('Error subscribing:', error);
  }
};

// displaying current year
const currentYear = new Date().getFullYear();
  return (
    
    <>
    {/* Header section */}
    <header>
      <div class="header-container">
        <div>
          <li><Link to='/'><img  class="logo" src={mylogo} alt="Sakazi Devs"/></Link></li>
        </div>

        <div class="navlinks">
          <ul>
            <li><Link to='/contact'>Contact</Link></li>
            <li>
            <div className="menu-icon open-icon" onClick={toggleMenu}>
            <div className="hamburger-icon">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          </div>
          </li>
          </ul>
            
            {isOpen && (
              <div className="fullscreen-menu">
                <div className="menu-icon close-icon" onClick={toggleMenu}>
                </div>
                
                <div className="menu-links">
                  <li><Link to='/services' onClick={toggleMenu}>Services</Link></li>
                  <li><Link to='/about' onClick={toggleMenu}>About</Link></li>
                  <li><Link to='/careers' onClick={toggleMenu}>Careers</Link></li>
                  <li><Link to='/faqs' onClick={toggleMenu}>FAQs</Link></li>
                  <li><Link to='/referral-program' onClick={toggleMenu}>Referral Program</Link></li>

                  <div className='navbar-bottom'>
                    <div className='social-links'>
                        <li><Link to='https://twitter.com/sakazi_devs'><i class="fa fa-twitter-square"></i></Link></li>
                        <li><Link to='https://facebook.com/sakazidevs'><i class="fa fa-facebook-square"></i></Link></li>
                        <li><Link to='https://www.linkedin.com/in/sakazidevs'><i class="fa fa-linkedin-square"></i></Link></li>
                        <li><Link to='https://github.com/sakazidevs'><i class="fa fa-github"></i></Link></li>
                    </div>
                    <div className='bottom-right-items'>
                      <h1>Let's get cracking</h1>
                      <li><Link to='/quote' onClick={toggleMenu}>Start your Project</Link></li>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
    </div> 


    </header>


    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/services" element={<Services />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/quote" element={<Quote />} />
    <Route path="/careers" element={<Careers />} />
    <Route path="/FAQs" element={<FAQs />} />
    <Route path="/referral-program" element={<ReferralProgram />} />
    <Route path="/privacy-policies" element={<PrivacyPolicy />} />
    <Route path='/terms-and-conditions' element={<Terms />} />
    {/* various services */}
    <Route path="/services/web-development" element={<WebDevelopment />} />
    <Route path="/services/content-management-systems" element={<CMS />} />
    <Route path="/services/graphic-design" element={<GraphicDesign />} />
    <Route path="/services/ui-&-ux-design" element={<UIUXDesign />} />
    <Route path="/services/search-engine-optimization" element={<SEO />} />

    {/* Various blogs */}
    <Route path="/blogs/top-trends-in-website-development-for-2024" element={<WebsiteDevelopmentTrends2022 />} />
    <Route path="/blogs/the-importance-of-responsive-web-design-for-mobile-users" element={<ResponsiveWebDesignImportance />} />
    <Route path="/blogs/enhancing-cybersecurity-measures-for-small-businesses" element={<CybersecurityMeasuresSmallBusinesses />} />

    {/* Redirect to ErrorPage for any unmatched route */}
    <Route path="*" element={<ErrorPage />} />
    <Route path="/user/unsubscribe" element={<Unsubscribe />} />
</Routes>


    {/* Footer section starts here*/}
    <footer>
        {/* This is footer-top */}
        <div class="footer-top">
              <div class="footer-card">
              
              <ul>
                  <li><Link to='/about'>About</Link></li>
                  <li><Link to='/services'>Services</Link></li>
                  <li><Link to='/careers'>Careers</Link></li>
                  <li><Link to='/referral-program'>Referral Program</Link></li>
                  <li><Link to='/privacy-policies'>Privacy Policy</Link></li>
                  <li><Link to='/terms-and-conditions'>Terms & Conditions</Link></li>
              </ul>
            </div>

              <div class="footer-card">     
              <ul>
                <h3>EXPERT IN</h3>
                  <li><Link to='/services/web-development'>Web Designing</Link></li>
                  <li><Link to='/services/graphic-design'>Graphic Designing</Link></li>
                  <li><Link to='/services/ui-&-ux-design'>UI and UX Designing</Link></li>
                  <li><Link to='/services/search-engine-optimization'>Search Engine Optimization</Link></li>
                  <li><Link to='/services/content-management-systems'>Content Management Systems</Link></li>
              </ul>
            </div>

              <div class="footer-card">
              <ul>
                <h3>NEED HELP?</h3>
                  <li><Link to='/contact'>Contact</Link></li>
                  <li><Link to='https://wa.me/254746092415/'>Live Chat</Link></li>
                  <li><Link to='/faqs'>FAQs</Link></li>
                  <li><Link onClick={openModal}>Report a problem</Link></li>
              </ul>
            </div>

              <div class="footer-card">
                <h3>FOLLOW</h3>
              <ul>
                
                  <li><Link to='https://twitter.com/sakazi_devs'><i class="fa fa-twitter-square"></i></Link></li>
                  <li><Link to='https://facebook.com/sakazidevs'><i class="fa fa-facebook-square"></i></Link></li>
                  <li><Link to='https://www.linkedin.com/in/sakazidevs'><i class="fa fa-linkedin-square"></i></Link></li>
                  <li><Link to='https://github.com/sakazidevs'><i class="fa fa-github"></i></Link></li>
              </ul>

                <form onSubmit={handleSubscribe}>
                <p>Get all updates through our Newsletter</p><br />
                <input 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  placeholder="Enter your email" 
                  required 
                />
                <button type="submit" disabled={subscribed} className='footer-btn'>
                  {subscribed ? 'Subscribed' : 'Subscribe'}
                </button>
              </form>
              <a href="mailto:info@sakazidevs.com" class="ouremail">info@sakazidevs.com</a><br />
              <a href="#0746092415">+254 746092415</a>            
            </div>
          </div>
        
       {/* scroll to top */}
       <button className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
      <i className="fas fa-arrow-up"></i>
    </button>

    {/* request quote button */}
    <Link to="/quote">
    <button
      className={`scroll-quote-button ${isVisibleOnScroll ? 'visible' : 'hidden'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? 'Request Quote' : 'Quote'}
    </button>
    </Link>


{/* Report problem code */}

<div>
      {isModalOpen && (
        <div className="modal" onClick={handleModalClick}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>Contact Details</h2>
            <p><b>Email:</b> support@sakazidevs.com</p>
            <p><b>Phone:</b> +254746092415</p>
            <p>Please report any problems you encounter by contacting us via the provided email or phone number.</p>
          </div>
        </div>
      )}
    </div>

<br/>
    <p className='copyrights'>© {currentYear} Sakazi Devs. All rights reserved.</p>
    </footer>
    {/* footer section ends here */}
    </>
  );
}
export default App;

