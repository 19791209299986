import React, { useState, useEffect } from 'react';
import '../custom.css';

function ReferralProgram() {
  const [showMessage, setShowMessage] = useState(false);

  const handleClick = () => {
    setShowMessage(true);
  };


  // code for metadata
  useEffect(() => {
    document.title = "Our Referral Program";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Recommend someone to us and stand a chance to earn more money with us');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, refer someone to us, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  

  return (
    <>
  {/* First section */}
  <div className='refer-container'>
    <div className='text-container'>
        <h3>Affiliate program</h3>
        <h1>Join our Referral Program and start earning <i class="fa-solid fa-dollar-sign"></i></h1>
        <p>Share with others what we do our best. Refer them to us and stand a chance to earn lots of money.</p>
       <a href='#refer-someone'><button className='signup-btn'>Join Now</button></a> 
       <a href='#refer-someone'><button className='watch-btn'><i class="fa-solid fa-video"></i> Watch how it works</button></a> 
    </div>
  </div>

  {/* Second section */}
  <div className='guide-container'>
    <h2>Earn Money With Sakazi Devs!</h2>
    <span>Step by step guide</span>
    <div className='guide-box'>
        <div className='column-1'>
        <i class="fa-solid fa-user-plus"></i>
            <h3>Register as an affiliate</h3>
            <p>Be among the first to earn revenue for referring new clients to Sakazi Devs. 
                It's free to join - no fees and enjoy unlimited referrals.</p>
        </div>
        <div className='column-1'>
        <i class="fa-solid fa-crown"></i>
            <h3>Promote Sakazi Devs</h3>
            <p>Help in growing Realm Elevator's global client base. 
                When your visitors click on your link and request for our service, you make money.</p>
        </div>
        <div className='column-1'>
        <i class="fa-solid fa-money-bill-trend-up"></i>
            <h3>Start earning</h3>
            <p>Earn up 10% for each new client you refer to us on the total 
                payments they make for their project. We offer regular and flexible payments options.</p>
        </div>
    </div>
  </div>

  {/* Third section */}
  <div className='why-us-container'>
    <div className='box-1'>
      
    </div>
    <div className='box-2'>
    <h3><i class="fa-solid fa-wand-magic-sparkles"></i> Why Sakazi Devs?</h3>
    <div className='col-sections'>
    <div className='cols'>
        <h4>We're known</h4>
        <p>50+ people have used Realm to start and grow their businesses</p>
    </div>
    <div className='cols'>
        <h4>We're popular</h4>
        <p>We've worked with populars brands around the world</p>
    </div>
    <div className='cols'>
        <h4>We're everywhere</h4>
        <p>We operate on any country and kind of business</p>
    </div>
    <div className='cols'>
        <h4>We're affordable</h4>
        <p>Services start from standard $5 to PRO $1000+</p>
    </div>
    <div className='cols'>
        <h4>We're reliable</h4>
        <p>We work and deliver client's project on time</p>
    </div>
    <div className='cols'>
        <h4>We've got it all</h4>
        <p>From marketing to design, there’s a service fit for any audience</p>
    </div>
 </div>
    </div>
  </div>

  {/* Join section */}
  <div className='join-now-container'>
    <h1>Become an affiliate partner!</h1>
    <p>Apply now or contact our support at <span>support@sakazidevs.com</span></p>

    <div className="coming-soon-container" id='refer-someone'>
      <button onClick={handleClick}>Join Now</button>
      {showMessage && <p className="message">User's dashboard under maintenance. Check back later. You can possibly refer client to us by calling <b>+254746092415</b> or <b>info@sakazidevs.com</b></p>}
    </div>

  </div>
    </>
  );
}

export default ReferralProgram;