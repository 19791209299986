import React, {useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../services.css';

import cmsImage1 from '../media/cms-image-1.jpg'
import cmsImage2 from '../media/cms-image-2.jpg'
import cmsImage3 from '../media/cms-image-3.jpg'
import webImage4 from '../media/web-image-4.png'

import webImage5 from '../media/web-image-5.png'
import webImage6 from '../media/web-image-6.png'
import webImage7 from '../media/web-image-7.jpg'
import webImage8 from '../media/web-image-8.jpg'
import webImage9 from '../media/web-image-9.jpg'

import ImageProject1 from '../media/home-project-1.png';
import ImageProject2 from '../media/home-project-2.png';

function CMS() {

// code for metadata
  useEffect(() => {
    document.title = "Content Management Systems";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'We specialize in creating customized Content Management Systems (CMS) utilizing platforms like WordPress, Shopify, and Wix to empower your online presence and streamline content management!');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, terms, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  

  return (
  <>
  {/* section 1 */}
  <section className='service-container'>
  <div class="image-container">
            <div class="image-scroll">
                {/* Images for scrolling */}
                <img src={webImage6} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage8} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage9} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage5} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage7} loading='lazy' alt='Sakazi Devs'/>
            </div>
        </div>
    <div className='text-container'>
    <h1> Expert CMS Development Services Online </h1>
    <p>We specialize in creating customized Content Management Systems (CMS) utilizing platforms like <span>WordPress, Shopify, and Wix</span> to empower your online presence and streamline content management!
    </p>
    </div>
  </section>


{/* Section 2 */}
<section className='service-container-1'>
    <div class="column-1">
        <h1>
            Tailored CMS Solutions for Your Business Goals
        </h1>
    </div>
    <div class="column-2">
        <p>
            Your website's content management system is the backbone of your online presence: it should be intuitive, robust, and scalable to meet your evolving needs.<br /><br />
            At Sakazi Devs, we excel in developing custom CMS solutions on platforms like WordPress, Shopify, and Wix that empower you to manage your content effortlessly. Guided by your business objectives, our development team will create a tailored CMS that streamlines content creation, editing, and publishing.<br /><br />
            Leveraging the capabilities of these platforms, we'll integrate custom features and functionalities to enhance your website's performance and user experience.<br /><br />
            The result is a bespoke CMS solution that's user-friendly, efficient, and equipped to support your business growth.
        </p>
    </div>
</section>

{/* Section 3 */}
<section className="service-container-2">
    <div class="column-1">
        <h1>Why Choose Us for your CMS?</h1>
    </div>
    <div class="column-2">
        <div class="card">
            <h2>Expertise</h2>
            <p>We're seasoned developers with extensive experience in building CMS solutions on platforms like WordPress, Shopify, and Wix, ensuring high-quality and reliable results.</p>
        </div>
        <div class="card">
            <h2>Customization</h2>
            <p>We tailor CMS solutions to fit your unique requirements, incorporating custom features and functionalities that align with your business objectives.</p>
        </div>
        <div class="card">
            <h2>Scalability</h2>
            <p>Our CMS solutions are designed to grow with your business, offering scalability and flexibility to accommodate future expansion and changes.</p>
        </div>
        <div class="card">
            <h2>Support</h2>
            <p>We provide ongoing support and maintenance services to ensure your CMS operates smoothly and remains up-to-date with the latest features and security patches.</p>
        </div>
    </div>
</section>

{/* Section 4 */}
<section className="service-container-3">
    <div class="column-1">
        <div class="image-container">
            <img src={cmsImage1} alt="Sakazi CMS Development" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Custom WordPress Solutions</h1>
            <p>We specialize in developing bespoke WordPress websites and plugins tailored to your specific business needs, providing you with full control over your online content.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>Shopify Store Development</h1>
            <p>From setup to customization, we build Shopify stores that are visually appealing, easy to manage, and optimized for conversions, helping you succeed in the competitive e-commerce landscape.</p>
        </div>
        <div class="image-container">
            <img src={cmsImage2} alt="Sakazi Shopify Development" loading='lazy'/>
        </div>
    </div>

    <div class="column-1">
        <div class="image-container">
            <img src={cmsImage3} alt="Sakazi Wix Development" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Wix Website Creation</h1>
            <p>We leverage the flexibility of Wix to design stunning websites that reflect your brand identity and engage your audience, ensuring a seamless user experience across all devices.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>Continuous Optimization</h1>
            <p>Our team continually monitors and optimizes your CMS for performance, security, and user experience, ensuring your website remains competitive and effective in achieving your business goals.</p>
        </div>
        <div class="image-container">
            <img src={webImage4} alt="Sakazi CMS Optimization" loading='lazy'/>
        </div>
    </div>
    <section class="wave-container">
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        <div class="wave wave4"></div>
    </section>
</section>


{/* service-container-4 section */}
<section className="service-container-4">
  <div class="column-1">
    <div class="text-h">
      <h2>CMS Development <br />Workflow</h2>
    </div>
    <div class="text-p">
      <p>This illustrates the stages of our typical content management system (CMS) development process, from planning to deployment. Our developers specialize in creating custom CMS solutions tailored to your specific needs.</p>
    </div>
  </div>
  <div class="steps">Steps involved</div>
  <div class="column-2">

    {/* drop-containers */}
    <div class="drop" style={{ '--crl': 'red' }}>
      <div class="drop-content">
        <h4>Step 1</h4>
        <h2>Requirement gathering and analysis</h2>
      </div>
    </div>

    <div class="drop" style={{ '--crl': 'purple' }}>
      <div class="drop-content">
        <h4>Step 2</h4>
        <h2>Designing the CMS architecture</h2>
      </div>
    </div>
    <div class="drop" style={{ '--crl': 'blue' }}>
      <div class="drop-content">
        <h4>Step 3</h4>
        <h2>Development and customization</h2>
      </div>
    </div>

    <div class="drop" style={{ '--crl': 'green' }}>
      <div class="drop-content">
        <h4>Step 4</h4>
        <h2>Testing, deployment, and client training</h2>
      </div>
    </div>
  </div>
</section>


{/* service-container-5 section */}
<section className='service-container-5'>
        <div class="column-1">
            <h1>Featured <br />Projects</h1>
            <div class="img-container">
                <img src={ImageProject2} alt="Sakazi Devs Featured Projects" loading='lazy'/>
                <div class="text-content">
                    <h2>Learntechpress</h2>
                    <p>We crafted and developed learning management system for Learntechpress learning community!</p>
                </div>
            </div>
        </div>
    <div class="column-2">
        <div class="img-container">
            <img src={ImageProject1} alt="Sakazi Devs Featured Projects" loading='lazy'/>
            <div class="text-content">
                <h2>Wendo News</h2>
                <p>We crafted a very beautiful website design for wendo news company</p>
            </div>
        </div>
    </div>
</section>



{/* service container 6 */}
<section className='service-container-6'>
<div className='text-container'>
    <h1>Get Started with Our Content Management Systems Services</h1>
    <p>Our expertise in content management systems allows us to create tailored solutions using platforms like WordPress, Shopify, Wix, and more. Whether you need a custom WordPress website, an e-commerce store on Shopify, or a user-friendly site on Wix, we have the skills to bring your vision to life.</p>
    <Link to="/quote">Start your project</Link>
</div>

    <div class="image-container">
              <div class="image-scroll">
                  {/* Images for scrolling */}
                  <img src={webImage6} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage8} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage9} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage5} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage7} loading='lazy' alt='Sakazi Devs'/>
              </div>
          </div>
    </section>


    {/* service container 7 section */}
    <section className='service-container-7'>

    </section>
  </>
  );
}

export default CMS;