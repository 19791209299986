import '../App.css';
import {useEffect} from 'react';

function PrivacyPolicy() {

// code for metadata
useEffect(() => {
    document.title = "Privacy Policies";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'At Sakazi Devs Company, we are dedicated to safeguarding your privacy and ensuring the protection of your personal information.');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  
  return (
 <>
 <div className='policies-container'>
 <h1>Privacy Policies</h1>
<div className='policy-box'>
<p>Last Updated: <span><b>20th April, 2024</b></span><br /><br />
At Sakazi Devs Company, we are dedicated to safeguarding your privacy and ensuring the protection of your personal information. 
This Privacy Policy outlines the manner in which we collect, use, 
disclose, and protect your information when you engage with our website, products, and services.</p>
<h2>1. Information We Collect</h2>
<p>
We may collect various types of information when you interact with our website or utilize our services, including but not limited to:<br />
<ul>
    <li><span><b><i class="fa fa-circle"></i> Personal Information:</b></span> This encompasses data such as your name, email address, phone number, mailing address, and any other 
        information you voluntarily provide to us.</li>
    <li><span><b><i class="fa fa-circle"></i> Usage Information:</b></span> We may gather information regarding your interactions with our website, including your IP address, 
        browser type, operating system, pages visited, and other usage statistics.</li>
    <li><span><b><i class="fa fa-circle"></i> Payment Information:</b></span> If you engage in financial transactions with us, we may collect payment-related information 
        such as credit card details, billing address, and transaction history.</li>
    <li><span><b><i class="fa fa-circle"></i> Cookies and Tracking Technologies:</b></span> We may employ cookies, web beacons, and similar tracking technologies to enhance 
        your browsing experience, analyze trends, administer the website, and gather demographic information.</li>
</ul>
</p>
<h2>2. How We Use Your Information</h2>
<p>
We utilize the information we collect for various purposes, including but not limited to:<br />
<ul>
    <li><i class="fa fa-circle"></i> Providing and maintaining our products and services.</li>
    <li><i class="fa fa-circle"></i> Communicating with you regarding your inquiries, orders, or account-related matters.</li>
    <li><i class="fa fa-circle"></i> Personalizing your experience and tailoring our offerings to better suit your preferences.</li>
    <li><i class="fa fa-circle"></i> Processing payments, fulfilling orders, and delivering requested services.</li>
    <li><i class="fa fa-circle"></i> Sending you marketing communications, promotional offers, or other relevant information, 
        subject to your consent where required by applicable law.</li>
    <li><i class="fa fa-circle"></i> Complying with legal obligations, enforcing our policies, and protecting our rights and interests.</li>
</ul>
</p>
<h2>3. Information Sharing and Disclosure</h2>
<p>
We may share your information with third parties under certain circumstances, including:<br />
<ul>
    <li><span><b><i class="fa fa-circle"></i> Service Providers:</b></span> We may engage third-party service providers to assist us in delivering our services, 
        processing payments, analyzing data, or conducting marketing activities.</li>
    <li><span><b><i class="fa fa-circle"></i> Business Transfers:</b></span> In the event of a merger, acquisition, or sale of assets, 
        your information may be transferred to a third party as part of the transaction.</li>
    <li><span><b><i class="fa fa-circle"></i> Legal Compliance:</b></span> We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests, or to enforce our rights and agreements.</li>
</ul>
</p>
<h2>4. Your Rights</h2>
<p>
You have certain rights regarding your personal information, including:<br />
<ul>
    <li><i class="fa fa-circle"></i> The right to access, update, or correct your information.</li>
    <li><i class="fa fa-circle"></i> The right to request the deletion of your information under certain circumstances.</li>
    <li><i class="fa fa-circle"></i> The right to object to the processing of your information or to withdraw your consent for processing, where applicable.</li>
    <li><i class="fa fa-circle"></i> The right to lodge a complaint with a supervisory authority if you believe that we have violated your rights under applicable data protection laws.</li>
</ul>
<p>To exercise your rights or inquire about the handling of your information, please contact us using the information provided below in this page.</p>
</p>
<h2>5. Data Security</h2>
<p>
We implement appropriate technical and organizational measures to safeguard your information against unauthorized access, disclosure, alteration, or destruction. However, 
please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute protection of your data.
</p>
<h2>6. Changes to this Privacy Policy</h2>
<p>
We reserve the right to update or modify this Privacy Policy at any time, in response to changes in our business practices, legal requirements, or technological advancements. 
We will notify you of any material changes by posting the updated policy on our website or through other appropriate means of communication.
</p>
<h2>7. Contact Us</h2>
<p>
If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <b>support@sakazidevs.com</b> or <b>+254746092415</b>.
</p>
</div>
</div>
</>
  );
}
export default PrivacyPolicy;