import React, {useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../services.css';
import webImage1 from '../media/web-image-1.jpg'
import webImage2 from '../media/web-image-2.jpg'
import webImage3 from '../media/web-image-3.jpg'
import webImage4 from '../media/web-image-4.png'

import webImage5 from '../media/web-image-5.png'
import webImage6 from '../media/web-image-6.png'
import webImage7 from '../media/web-image-7.jpg'
import webImage8 from '../media/web-image-8.jpg'
import webImage9 from '../media/web-image-9.jpg'

import ImageProject1 from '../media/home-project-1.png';
import ImageProject2 from '../media/home-project-2.png';

function WebDevelopment() {

// code for metadata
  useEffect(() => {
    document.title = "Web Design";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'We utilize modern UX and UI Design technologies to deliver an exceptional websites and web applications that not only capture your customers attention but also help you increase sales!');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, terms, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  

  return (
  <>
  {/* section 1 */}
  <section className='service-container'>
  <div class="image-container">
            <div class="image-scroll">
                {/* Images for scrolling */}
                <img src={webImage6} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage8} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage9} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage5} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage7} loading='lazy' alt='Sakazi Devs'/>
            </div>
        </div>
    <div className='text-container'>
    <h1>Professional Web Design Services Online</h1>
    <p>We specialize in crafting stunning, user-centric websites that not only <span>capture attention</span> but also drive engagement and conversions!
    </p>
    </div>
  </section>


{/* Section 2 */}
<section className='service-container-1'>
    <div class="column-1">
        <h1>
            Custom Web Designs Tailored to Your Brand
        </h1>
    </div>
    <div class="column-2">
        <p>
            Your website is the digital face of your brand: it should be visually captivating, intuitive, and reflective of your brand identity.<br /><br />
            At Sakazi Designs, we excel in creating bespoke web designs that resonate with your audience and elevate your brand presence. Guided by your brand guidelines and objectives, our design team will craft unique designs that set you apart from the competition.<br /><br />
            Utilizing the latest design trends and techniques, we'll incorporate subtle animations and interactive elements to enhance user experience while maintaining functionality.<br /><br />
            The result is a visually stunning website that's responsive, user-friendly, and optimized for conversions.
        </p>
    </div>
</section>

{/* Section 3 */}
<section className="service-container-2">
    <div class="column-1">
        <h1>Why Choose Us for Web Design?</h1>
    </div>
    <div class="column-2">
        <div class="card">
            <h2>Creative Design</h2>
            <p>We're passionate about design and strive to create visually compelling websites that leave a lasting impression on your audience.</p>
        </div>
        <div class="card">
            <h2>User-Centric Approach</h2>
            <p>We prioritize user experience in our designs, ensuring that every element is carefully crafted to enhance usability and engagement.</p>
        </div>
        <div class="card">
            <h2>Customization</h2>
            <p>We tailor our designs to fit your brand identity and business goals, providing you with a unique online presence that reflects your values and objectives.</p>
        </div>
        <div class="card">
            <h2>Collaborative Process</h2>
            <p>We believe in open communication and collaboration throughout the design process, ensuring that your vision is brought to life effectively.</p>
        </div>
    </div>
</section>

{/* Section 4 */}
<section className="service-container-3">
    <div class="column-1">
        <div class="image-container">
            <img src={webImage2} alt="Sakazi Web Design" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Responsive Design</h1>
            <p>We design websites that look and perform flawlessly across all devices, providing users with a seamless experience whether they're on desktop, tablet, or mobile.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>Engaging Visuals</h1>
            <p>From typography to imagery, we pay attention to every detail to ensure that your website's visuals are captivating and align with your brand's aesthetic.</p>
        </div>
        <div class="image-container">
            <img src={webImage1} alt="Sakazi Web Design Visuals" loading='lazy'/>
        </div>
    </div>

    <div class="column-1">
        <div class="image-container">
            <img src={webImage3} alt="Sakazi Web Design Illustrations" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Intuitive Navigation</h1>
            <p>We prioritize user-friendly navigation to ensure that visitors can easily find what they're looking for and navigate through your website with ease.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>Optimized Performance</h1>
            <p>We optimize our websites for speed and performance, ensuring fast load times and smooth functionality to provide users with a seamless browsing experience.</p>
        </div>
        <div class="image-container">
            <img src={webImage4} alt="Sakazi Web Design Performance" loading='lazy'/>
        </div>
    </div>
    <section class="wave-container">
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        <div class="wave wave4"></div>
    </section>
</section>


{/* service-container-4 section */}
<section className="service-container-4">
<div class="column-1">
<div class="text-h">
<h2>Website <br />Workflow</h2>
</div>
<div class="text-p">
<p>This shows the stages of our typical website design and development process, 
from the initial kick-off meeting all the way to launch day. Our project 
managers will work with you every step of the way to ensure you have visibility over this process.</p>
</div>
</div>
<div class="steps">Steps involved</div>
<div class="column-2">

{/* drop-containers */}
<div class="drop" style={{ '--crl': 'red' }}>
    <div class="drop-content">
        <h4>Step 1</h4>
          <h2>We read and understand your project requirements</h2>
    </div>
</div>

<div class="drop" style={{ '--crl': 'purple' }}>
    <div class="drop-content">
        <h4>Step 2</h4>
          <h2>We start working on your project</h2>
    </div>
</div>
<div class="drop" style={{ '--crl': 'blue' }}>
    <div class="drop-content">
        <h4>Step 3</h4>
          <h2>We do debugging and testing the website</h2>
    </div>
</div>

<div class="drop" style={{ '--crl': 'green' }}>
    <div class="drop-content">
        <h4>Step 4</h4>
          <h2>Finally we deliver your work</h2>
    </div>
</div>
</div>
</section>


{/* service-container-5 section */}
<section className='service-container-5'>
        <div class="column-1">
            <h1>Featured <br />Projects</h1>
            <div class="img-container">
                <img src={ImageProject2} alt="Sakazi Devs Featured Projects" loading='lazy'/>
                <div class="text-content">
                    <h2>Learntechpress</h2>
                    <p>We crafted and developed learning management system for Learntechpress learning community!</p>
                </div>
            </div>
        </div>
    <div class="column-2">
        <div class="img-container">
            <img src={ImageProject1} alt="Sakazi Devs Featured Projects" loading='lazy'/>
            <div class="text-content">
                <h2>Wendo News</h2>
                <p>We crafted a very beautiful website design for wendo news company</p>
            </div>
        </div>
    </div>
</section>



{/* service container 6 */}
<section className='service-container-6'>
<div className='text-container'>
    <h1>Get Started with Our Expert Web Design Services</h1>
    <p>We utilize modern UX and UI Design technologies to deliver exceptional websites that not only capture 
    your customers' attention but also help you increase sales! Our expertise includes designing various types of websites, such as e-commerce websites, portfolio websites, business websites, blog websites, and more.</p>
    <Link to="/quote">Start your project</Link>
</div>

    <div class="image-container">
              <div class="image-scroll">
                  {/* Images for scrolling */}
                <img src={webImage6} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage8} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage9} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage5} loading='lazy' alt='Sakazi Devs'/>
                <img src={webImage7} loading='lazy' alt='Sakazi Devs'/>
              </div>
          </div>
    </section>


    {/* service container 7 section */}
    <section className='service-container-7'>

    </section>
  </>
  );
}

export default WebDevelopment;