import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faRocket, faShoppingCart, faMapMarkerAlt, faPencilAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import webImage4 from '../media/services-web-4.jpg';
import '../otherservices.css';

const SEO = () => {

  // code for metadata
  useEffect(() => {
    document.title = "Search Engine Optimization";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Boost your online visibility and drive more traffic to your website with our expert SEO services');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, terms, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);


  return (
    <div className="seo-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <div className="hero-content">
            <h1>Search Engine Optimization <span>Services</span></h1>
            <p>Boost your online visibility and drive more traffic to your website with our expert SEO services.</p>
            <Link to="/quote"><button className="cta-button">Get Started</button></Link>
          </div>
          <div className="hero-image">
            <img src={webImage4} alt="SEO" loading='lazy'/>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <div className="container">
          <h2>Why Choose Our SEO Services?</h2>
          <div className="feature-list">
            <div className="feature">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faGlobe} />
              </div>
              <h3>Increased Online Visibility</h3>
              <p>Our SEO strategies will help your website rank higher in search engine results, increasing your online visibility and reach.</p>
            </div>
            <div className="feature">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faChartBar} />
              </div>
              <h3>Data-Driven Approach</h3>
              <p>We use data-driven techniques and advanced analytics to track and optimize your SEO performance, ensuring maximum impact.</p>
            </div>
            <div className="feature">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faRocket} />
              </div>
              <h3>Proven Results</h3>
              <p>Our team of SEO experts has a proven track record of delivering measurable results and helping businesses achieve their online goals.</p>
            </div>
          </div>
        </div>
      </section>



{/* service-container-4 section */}
<section className="service-container-4">
<div class="column-1">
<div class="text-h">
<h2>SEO <br />Workflow</h2>
</div>
<div class="text-p">
<p>This shows the stages we follow from understanding your SEO demands to fully implenting and testing it.</p>
</div>
</div>
<div class="steps">Steps involved</div>
<div class="column-2">


{/* drop-containers */}
<div class="drop" style={{ '--crl': 'red' }}>
    <div class="drop-content">
        <h4>Step 1</h4>
          <h2>Conduct a comprehensive audit and analysis of your website</h2>
    </div>
</div>

<div class="drop" style={{ '--crl': 'purple' }}>
    <div class="drop-content">
        <h4>Step 2</h4>
          <h2>Develop a tailored SEO strategy</h2>
    </div>
</div>
<div class="drop" style={{ '--crl': 'blue' }}>
    <div class="drop-content">
        <h4>Step 3</h4>
          <h2>Optimize your website's content, structure, and metadata</h2>
    </div>
</div>

<div class="drop" style={{ '--crl': 'green' }}>
    <div class="drop-content">
        <h4>Step 4</h4>
          <h2>Monitor your website's performance and provide regular reports</h2>
    </div>
</div>
</div>
</section>



 {/* Use Cases Section */}
<section className="use-cases">
  <div className="container">
    <div className="use-cases-content">
      <div className="use-cases-header">
        <h2>SEO Use Cases</h2>
      </div>
      <div className="use-case-carousel">
        <div className="use-case-list">
          <div className="use-case-card">
            <div className="use-case-icon">
              <FontAwesomeIcon icon={faShoppingCart} />
            </div>
            <div className="use-case-content">
              <h4>E-commerce Websites</h4>
              <p>Increase online visibility and drive more traffic to your e-commerce website, boosting sales and revenue.</p>
            </div>
          </div>
          <div className="use-case-card">
            <div className="use-case-icon">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <div className="use-case-content">
              <h4>Local Businesses</h4>
              <p>Improve your local search rankings and attract more customers in your area with our local SEO strategies.</p>
            </div>
          </div>
          <div className="use-case-card">
            <div className="use-case-icon">
              <FontAwesomeIcon icon={faPencilAlt} />
            </div>
            <div className="use-case-content">
              <h4>Content Marketing</h4>
              <p>Optimize your content marketing efforts by targeting the right keywords and improving your content's visibility in search engines.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

     
      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2>Ready to dominate search engines?</h2>
          <p>Contact us today to learn more about how our SEO services can boost your online visibility and drive more traffic to your website.</p>
          <Link to="/quote"><button className="cta-button">Get Started</button></Link>
        </div>
      </section>
    </div>
  );
};

export default SEO;