import '../custom.css';
import { useEffect } from 'react';
import CareersSection from './CareersSection';

function Careers() {
  useEffect(() => {
  document.title = "Careers";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Explore available careers opportunities at Sakazi Devs');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'careers, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);

    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <>
      {/* Careers page */}
      <section className='top-career-container'>
        <div className='text-container'>
          <h5>Careers at Sakazi Devs</h5>
          <h1>Be a part of <br />something great</h1>
          <a href='#positions'>Explore more <i className="fa fa-long-arrow-right"></i></a>
        </div>
      </section>

      {/* careers container 1 */}
      <div className='careers-container-1'>
        <div className='column-1'>
          <h1>A Little bit about <br /><span>Sakazi Devs</span></h1>
          <p>Sakazi Devs is a premier IT services provider in Kenya and beyond. Located in the heart of Nairobi, we are dedicated to revolutionizing the digital landscape by offering a comprehensive suite of services tailored to meet the unique needs of businesses across the region. From web design to consultancy, SEO optimization to graphic design, our expert team is committed to delivering innovative solutions that drive growth and success for our clients.</p>
        </div>

        <div className='column-2'>
          {/* it contains background image */}
        </div>
      </div>

      {/* Container 2 section */}
      <div className='container-2-heading'>What do we stand for?</div>
      <section className="careers-container-2">
        <div className="column-1">
          <i className="fas fa-rocket"></i>
          <h3>Our Mission</h3>
          <p>To empower Kenyan businesses and beyond with cutting-edge IT solutions that foster growth, efficiency, and success. We aim to be the catalyst for digital transformation, helping businesses thrive in an increasingly competitive marketplace.</p><br />
        </div>
        <div className="column-2">
          <i className="fas fa-lightbulb"></i>
          <h3>Our Vision</h3>
          <p>To be the leading provider of innovative IT services in Kenya, renowned for our commitment to quality, creativity, and client satisfaction. We aspire to be the partner of choice for businesses seeking to leverage technology to achieve their goals and surpass their expectations.</p><br />
        </div>
        <div className="column-3">
          <i className="fas fa-hand-holding-heart"></i>
          <h3>Our Core Values</h3>
          <ul>
            <li>Innovative</li>
            <li>Reliable</li>
            <li>Collaborative</li>
            <li>Customer-satisfaction</li>
          </ul>
        </div>
      </section>
      <br />
      {/* This is careers section */}
      <div className='career-container-3' id='positions'>
        <h1>Available Positions</h1>
        <CareersSection />
      </div>
    </>
  );
}

export default Careers;
