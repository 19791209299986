import React, {useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../services.css';
import uiImage1 from '../media/ui-image-1.jpg'
import uiImage2 from '../media/ui-image-2.jpg'
import uiImage3 from '../media/ui-image-3.jpg'
import uiImage4 from '../media/ui-image-4.jpg'
import uiImage5 from '../media/ui-image-5.jpg'
import uiImage6 from '../media/ui-image-6.jpg'
import uiImage7 from '../media/ui-image-7.jpg'
import uiImage8 from '../media/ui-image-8.jpg'
import uiImage9 from '../media/ui-image-9.jpg'
import uiImage10 from '../media/ui-image-10.png'

import ImageProject1 from '../media/home-project-1.png'
import ImageProject2 from '../media/home-project-2.png'


function UIUXDesign() {

// code for metadata
  useEffect(() => {
    document.title = "UI & UX Design";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'We harness the latest UI/UX design methodologies to deliver exceptional digital experiences that not only engage your users but also drive conversions!');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, terms, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  

  return (
  <>
  {/* section 1 */}
  <section className='service-container'>
  <div class="image-container">
            <div class="image-scroll">
                {/* Images for scrolling */}
                <img src={uiImage5} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage6} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage7} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage8} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage9} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage10} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage4} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage2} alt="Sakazi Devs" loading='lazy'/>
                
            </div>
        </div>
    <div className='text-container'>
    <h1> Expert UI/UX Design Services Remotely </h1>
    <p>We harness the latest UI/UX design methodologies to deliver <span>exceptional digital experiences that not only engage your users</span> but also drive conversions!
    </p>
    </div>
  </section>


{/* Section 2 */}
<section className='service-container-1'>
    <div class="column-1">
        <h1>
            Intuitive Interfaces Crafted for User Engagement
        </h1>
    </div>
    <div class="column-2">
        <p>
            User experience is paramount in digital design: your interface should be intuitive, seamless, and delightful for users to interact with.<br /><br />
            At Sakazi Designs, we specialize in crafting intuitive UI/UX interfaces that align with user expectations, enhancing engagement and driving retention rates. Guided by user research, our design team will develop interfaces that prioritize user needs and goals.<br /><br />
            Leveraging cutting-edge front-end technologies, we create fluid interfaces and interactive elements that elevate user experiences without sacrificing functionality.<br /><br />
            The result is a user-centric design that's visually appealing, user-friendly, and optimized for conversion.
        </p>
    </div>
</section>

{/* Section 3 */}
<section className="service-container-2">
    <div class="column-1">
        <h1>What Sets Us Apart</h1>
    </div>
    <div class="column-2">
        <div class="card">
            <h2>Creative</h2>
            <p>We're innovators in UI/UX design, pushing boundaries to create interfaces that captivate and engage users, setting your brand apart from competitors.</p>
        </div>
        <div class="card">
            <h2>Insightful</h2>
            <p>We leverage user insights to inform design decisions, ensuring that every interaction is purposeful and enhances the overall user experience.</p>
        </div>
        <div class="card">
            <h2>Adaptive</h2>
            <p>Our designs seamlessly adapt to various screen sizes and devices, providing a consistent and optimal experience across all platforms.</p>
        </div>
        <div class="card">
            <h2>Results-Driven</h2>
            <p>We focus on designing interfaces that drive results, whether it's increasing conversions, reducing bounce rates, or improving user satisfaction metrics.</p>
        </div>
    </div>
</section>

{/* Section 4 */}
<section className="service-container-3">
    <div class="column-1">
        <div class="image-container">
            <img src={uiImage1} alt="Sakazi UI/UX Design" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Strategic <br />UX Design</h1>
            <p>Prior to designing visuals, we conduct in-depth user research to understand user behaviors and pain points, ensuring that our designs address user needs effectively.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>User-Centric <br />UI Design</h1>
            <p>Starting with wireframes and prototypes, we design interfaces that prioritize user interactions, making every touchpoint intuitive and seamless.</p>
        </div>
        <div class="image-container">
            <img src={uiImage2} alt="Sakazi UI/UX Design Process" loading='lazy'/>
        </div>
    </div>

    <div class="column-1">
        <div class="image-container">
            <img src={uiImage3} alt="Sakazi UI/UX Design Elements" loading='lazy'/>
        </div>
        <div class="text-container">
            <h1>Interactive <br />UI Elements</h1>
            <p>Our UI designs incorporate interactive elements that engage users and encourage exploration, resulting in a more immersive digital experience.</p>
        </div>
    </div>
    <div class="column-2">
        <div class="text-container">
            <h1>Continuous Improvement</h1>
            <p>We believe in iterative design, constantly refining and optimizing our interfaces based on user feedback and analytics data to ensure ongoing success.</p>
        </div>
        <div class="image-container">
            <img src={uiImage4} alt="Sakazi UI/UX Design Iteration" loading='lazy'/>
        </div>
    </div>
    <section class="wave-container">
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        <div class="wave wave4"></div>
    </section>
</section>


{/* service-container-4 section */}
<section className="service-container-4">
  <div class="column-1">
    <div class="text-h">
      <h2>UI/UX Design <br />Workflow</h2>
    </div>
    <div class="text-p">
      <p>This outlines the steps of our typical UI/UX design process, from concept to delivery. Our designers focus on creating intuitive and engaging user experiences to enhance your product.</p>
    </div>
  </div>
  <div class="steps">Steps involved</div>
  <div class="column-2">

    {/* drop-containers */}
    <div class="drop" style={{ '--crl': 'red' }}>
      <div class="drop-content">
        <h4>Step 1</h4>
        <h2>Research and understand user needs and goals</h2>
      </div>
    </div>

    <div class="drop" style={{ '--crl': 'purple' }}>
      <div class="drop-content">
        <h4>Step 2</h4>
        <h2>Create wireframes and low-fidelity prototypes</h2>
      </div>
    </div>
    <div class="drop" style={{ '--crl': 'blue' }}>
      <div class="drop-content">
        <h4>Step 3</h4>
        <h2>Design high-fidelity mockups and prototypes</h2>
      </div>
    </div>

    <div class="drop" style={{ '--crl': 'green' }}>
      <div class="drop-content">
        <h4>Step 4</h4>
        <h2>Conduct user testing and iterate designs</h2>
      </div>
    </div>
  </div>
</section>


{/* service-container-5 section */}
<section className='service-container-5'>
        <div class="column-1">
            <h1>Featured <br />Projects</h1>
            <div class="img-container">
                <img src={ImageProject2} alt="Sakazi Devs Featured Projects" loading='lazy'/>
                <div class="text-content">
                    <h2>Learntechpress</h2>
                    <p>We crafted and developed learning management system for Learntechpress learning community!</p>
                </div>
            </div>
        </div>
    <div class="column-2">
        <div class="img-container">
            <img src={ImageProject1} alt="Sakazi Devs Featured Projects" loading='lazy'/>
            <div class="text-content">
                <h2>Wendo News</h2>
                <p>We crafted a very beautiful website design for wendo news company</p>
            </div>
        </div>
    </div>
</section>




{/* service container 6 */}
<section className='service-container-6'>
<div className='text-container'>
    <h1>Get Started with Our Expert UI/UX Design Services</h1>
    <p>We specialize in crafting user-centric designs that enhance the overall user experience of your digital products. Our UI/UX design services encompass a range of solutions tailored to your specific needs, including wireframing and prototyping, user research and testing, responsive web design, mobile app design, interaction design, and more.</p>
    <Link to='https://sakazidevs.com/quote'>Start your project</Link>
</div>

    <div class="image-container">
              <div class="image-scroll">
                  {/* Images for scrolling */}
                  <img src={uiImage5} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage6} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage7} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage8} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage9} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage10} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage4} alt="Sakazi Devs" loading='lazy'/>
                <img src={uiImage2} alt="Sakazi Devs" loading='lazy'/>
              </div>
          </div>
    </section>


    {/* service container 7 section */}
    <section className='service-container-7'>

    </section>
  </>
  );
}

export default UIUXDesign;