import '../style.css';
import axios from 'axios';
import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import Imageservices1 from '../media/services-web-1.png';
import Imageservices3 from '../media/services-web-2.jpg';
import Imageservices2 from '../media/services-web-3.jpg';
import Imageservices4 from '../media/services-web-4.jpg';
import Imageservices6 from '../media/services-web-6.jpg';





function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  // code for ad section
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

// code for metadata
useEffect(() => {
  document.title = "Services";

  const metaDescription = document.createElement('meta');
  metaDescription.setAttribute('name', 'description');
  metaDescription.setAttribute('content', 'We specialize in a wide range of services, tailored to meet your unique needs and drive your digital success. Services that we offer include, web design, graphic design, automation, Ai Integration, SEO, IT consultancy, Content Management Systems, UI ad UX Design.');
  document.head.appendChild(metaDescription);


  const metaKeywords = document.createElement('meta');
  metaKeywords.setAttribute('name', 'keywords');
  metaKeywords.setAttribute('content', 'services, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
  document.head.appendChild(metaKeywords);


  return () => {
    document.title = "";

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  };
}, []);



// submitting review code
const [reviews, setReviews] = useState([]);
const [averageRating, setAverageRating] = useState(0);
const [showForm, setShowForm] = useState(false);

useEffect(() => {
  fetchReviews();
}, []);

const fetchReviews = async () => {
  try {
    const response = await axios.get('https://server.sakazidevs.com/api/reviews');
    const lastSixReviews = response.data.reviews.slice(-6); 
    setReviews(lastSixReviews);
    calculateAverageRating(lastSixReviews);
  } catch (error) {
    console.error('Error fetching reviews:', error);
  }
};

const calculateAverageRating = (reviewsData) => {
  if (reviewsData.length === 0) {
    setAverageRating(0);
    return;
  }

  const totalRating = reviewsData.reduce((acc, curr) => acc + curr.rating, 0);
  setAverageRating(totalRating / reviewsData.length);
};

const handleSubmit = async (e) => {
  e.preventDefault();
  const { name, rating, company, reviewText } = e.target.elements;
  if (name.value && rating.value && reviewText.value) {
    try {
      const response = await axios.post('https://server.sakazidevs.com/api/reviews', {
        name: name.value,
        rating: rating.value,
        company: company.value || '',
        reviewText: reviewText.value,
      });
      console.log(response.data.message);
      fetchReviews(); 
      // Clear form fields
      name.value = '';
      rating.value = '';
      company.value = '';
      reviewText.value = '';
      // Hide the form after submission
      setShowForm(false);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  } else {
    alert('Please enter name, rating, and review text');
  }
};

const renderStars = () => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(<span key={i} className={i < Math.round(averageRating) ? 'star-filled' : 'star-empty'}>&#9733;</span>);
  }
  return stars;
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 768, 
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};
//submitting review code ends here


  return (
 <>
{/* this is service page */}

<section class="services">
<div class="service-box">
  <div class="column-1">
    <h1>SERV<br />ICES</h1>
  </div>
  <div class="column-2">
    <p>Sakazi Devs is a leading IT company dedicated to providing <span>innovative and high-quality solutions for 
      businesses of all sizes.</span> We specialize in a wide range of services, tailored to meet your 
      unique needs and drive your digital success. With a team of experienced professionals 
      and a passion for cutting-edge technologies, we deliver exceptional results that exceed expectations. 
      Explore our comprehensive services below and discover how we can empower your business.</p>
      <div class="links">
      <ul>
        <li><a href="#website"><i class="fa fa-paper-plane-o"></i> Web Development</a></li>
        <li><a href="#ui&ux-design"><i class="fa fa-paper-plane-o"></i> UI & UX Design</a></li>
        <li><a href="#graphic"><i class="fa fa-paper-plane-o"></i> Graphic Design</a></li>  
      </ul>
      <ul>
      <li><a href="#cms"><i class="fa fa-paper-plane-o"></i> CMS</a></li>
        <li><a href="#seo"><i class="fa fa-paper-plane-o"></i> SEO</a></li>
      </ul>
    </div>
  </div>
</div>


{/* services cards section */}

<div class="service-card">
<div class="box" id="website">
    <div class="column-1">
    <h2>Web<br />Development</h2>
    <p>Our expert web development team creates visually stunning and highly functional websites that 
      captivate your audience and enhance your online presence. We utilize <span>the latest web technologies and 
      follow industry best practices</span> to deliver responsive, user-friendly, and SEO-optimized websites.</p>
    <Link to="/services/web-development"><button>LEARN MORE</button></Link>
    </div>
    <div class="column-2">
<img src={Imageservices1} alt='web design' loading='lazy'/>
    </div>
  </div> 


  <div class="box" id="ui&ux-design">
    <div class="column-1">
    <h2>UI & UX<br />Design</h2>
    <p>Our software development services are tailored to meet your specific requirements, 
      whether it's building a robust enterprise application or a scalable software product.
      We follow <span>an agile development approach, leveraging modern technologies</span> to create reliable,
      secure, and scalable software solutions.</p>
    <Link to="/services/ui-&-ux-design"><button>LEARN MORE</button></Link>
    </div>
    <div class="column-2">
       <img src={Imageservices3} alt='ui and ux design' loading='lazy'/>
    </div>
  </div>

<div id="graphic"></div>
  <div class="box">
    <div class="column-1">
    <h2>Graphic Design</h2>
    <p>Our talented graphic design team brings creativity and innovation to your visual branding needs.
       <span> From logos and brand identity to marketing collaterals and UI/UX design,</span> we create compelling 
       designs that resonate with your target audience and elevate your brand image.</p>
    <Link to="/services/graphic-design"><button>LEARN MORE</button></Link>
    </div>
    <div class="column-2">
<img src={Imageservices2} alt='graphic design' loading='lazy'/>
    </div>
  </div>

  <div class="box" id="cms">
    <div class="column-1">
    <h2>Content Management Systems (CMS)</h2>
    <p>Simplify your digital presence with our customized Content Management Systems. From intuitive interfaces to seamless updates, manage your online content effortlessly. <span>We specialize in popular platforms such as WordPress, Drupal, Joomla, Magento, and Shopify,</span> ensuring that you have the right CMS solution tailored to your needs.</p>
    <Link to="/services/content-management-systems"><button>LEARN MORE</button></Link>
    </div>
    <div class="column-2">
        <img src={Imageservices6} alt='content management systems' loading='lazy'/>
    </div>
  </div>

  <div class="box" id="seo">
    <div class="column-1">
    <h2>Search Engine Optimization (SEO)</h2>
    <p>Enhance your online visibility and reach your target audience effectively with our Search Engine Optimization services. <span>We optimize your website to rank higher on search engines,</span> driving organic traffic and boosting your online presence.</p>
          <Link to="/services/search-engine-optimization"><button>LEARN MORE</button></Link>
    </div>
    <div class="column-2">
        <img src={Imageservices4} alt='search engine optimization' loading='lazy'/>
    </div>
  </div>

</div>
</section>


 {/* Testimonials Section */}
 <div className="testimonial-container" id='reviews'>
      <h1>Latest Reviews</h1>
      <p>Average Rating: {averageRating.toFixed(1)}</p>
      <div className="stars">
        {renderStars()}
      </div>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <p><strong>{review.name}</strong></p>
            <p style={{ color: "#777" }}><i>{review.company}</i></p>
            <p><FontAwesomeIcon icon={faQuoteLeft} size="lg" style={{ color: "#777" }}/>    {review.reviewText}</p>
          </div>
        ))}
      </Slider><br />
      <p>What can you say about services offered by Sakazi Devs?</p>
      <button onClick={() => setShowForm(true)}>Submit Review</button>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Name" required /><br />
          <input type="text" name="company" placeholder="Company/Business (Optional)" /><br />
          <textarea name="reviewText" placeholder="Write your review..." required></textarea><br />
          <select name="rating" required>
            <option value="">Select Rating</option>
            {[1, 2, 3, 4, 5].map((rating) => (
              <option key={rating} value={rating}>{rating}</option>
            ))}
          </select><br />
          <button type="submit">Submit</button>
          <button type="button" onClick={() => setShowForm(false)}>Cancel</button>
        </form>
      )}
    </div>
<br/>


{/* call to action */}
<section className='requesting-quote-container'>
  <div className='box'>
<h1>Don't know where to start?</h1>
<p>Incase you're stuck somewhere with your next project, don't hesitate, our team is ready to help you.</p>
<Link to="/quote"><button>Get started</button></Link>
</div>
</section>
<br/>
    {/* promoted ads */}
    <div className={`ad-section ${isClosed ? 'closed' : ''}`}>
      <div className="ad-header">
        <p className="ad-text">Ad</p>
        <button className="close-button" onClick={handleClose}>X</button>
      </div>
      <div className="promo-content">
        <h2>Ready to Host Your Website?</h2>
        <p>Are you looking for <b>reliable</b>, <b>stable</b>, <b>affordable</b> place to get hosting or purchase your domain for your website or web application? Hostpinnacle gets you covered, starting from 3,499 KSH yearly. Use our <b>Promolink!</b></p>
        <Link to="https://www.hostpinnacle.co.ke/clients/aff.php?aff=2063" target="_blank" rel="noopener noreferrer" className="promo-button">Get Started</Link>
      </div>
    </div>
 </>
  );
}

export default Services;