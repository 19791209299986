import '../contact.css';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link } from 'react-router-dom';




function Contact() {

//code to handlesubmit form
const [formData, setFormData] = useState({
  fullName: '',
  email: '',
  phoneNumber: '',
  subject: '',
  message: ''
});

const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    // Submit form data instantly
    const res = await axios.post('https://server.sakazidevs.com/api/contact', formData);
    console.log(res.data);
    // Reset form after successful submission
    setFormData({
      fullName: '',
      email: '',
      phoneNumber: '',
      subject: '',
      message: ''
    });
    // Show success message
    toast.success('Message sent successfully. Our team will get back to you soon.');
  } catch (err) {
    console.error(err);
    // Show error message if submission fails
    toast.error('Failed to send message. Please try again later.');
  }
};



  // code for metadata
  useEffect(() => {
    document.title = "Contact us";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Get in touch with our creator-friendly support team');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'contact us, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);


  
  return (
  <>

<section className='top-header'>
<h2>Contact Us</h2>
<p>Let’s discuss your business needs and how Sakazi Devs can help!</p>
{/* wavy svg */}
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280"><path fill="#F7F1ED" fill-opacity="1" d="M0,160L48,160C96,160,192,160,288,144C384,128,480,96,576,117.3C672,139,768,213,864,218.7C960,224,1056,160,1152,128C1248,96,1344,96,1392,96L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
</section>

{/* container 1 */}
<div className='contact-container-1'>
  <div className='box'>
  <i class="fas fa-question-circle"></i>
    <h2>General Enquiries</h2>
    <p>Would you like to discuss your business needs? 
      Our team will be delighted to assist you!</p><br />
  </div>

  <div className='box'>
  <i class="fa fa-handshake-o"></i>
    <h2>Partner with us</h2>
    <p>Interested in becoming partner of Sakazi Devs?
       We’re excited to hear about your plans and will happily assign you a partner manager!</p>
  </div>

  <div className='box'>
  <i class="fas fa-lightbulb"></i>
    <h2>Feature request</h2>
    <p>Share your ideas on new functionalities to make Sakazi Devs an even better product. 
      Suggest, discuss and vote for your favorite feature.</p>
  </div>

  <div className='box'>
  <i class="fas fa-headset"></i>
    <h2>Speak to support</h2>
    <p>We are available for you 24 x 7 x 365 – Save time by starting your 
      support request online and we’ll connect you to the Sakazi Devs expert.</p>
  </div>
</div>


{/* Contact form section */}
<div className='contact-form-heading'>
<h2>Let's start a conversation!</h2>
<h6>Get in touch with our creator-friendly support team</h6>
</div>
<section className='contact-container-2'>

<div className='form-gp'>
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Fredrick Kamau"
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        placeholder="example@gmail.com"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="tel"
        placeholder="+254 70000000"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        placeholder="Subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        required
      />
      <textarea
        placeholder="Message"
        name="message"
        rows={5}
        value={formData.message}
        onChange={handleChange}
        required
      ></textarea>
      <button type="submit">SUBMIT</button>
    </form>
    <ToastContainer />
  </div>


  <div className='quick-contacts-social'>
  <h2>Let's talk through a call/WhatsApp</h2>
   <div>
    <a href='https://wa.me/254746092415'>+254746092415</a>
   </div><br />
   <div className='social-icons'>
    <h2>Follow us</h2>
    <Link to='https://facebook.com/sakazidevs'> <i className='fa fa-facebook'></i></Link>
            <Link to='https://twitter.com/sakazi_devs'><i className='fa fa-twitter'></i></Link>
             <Link to='https://github.com/sakazidevs'><i className='fa fa-github'></i></Link>
             <Link to='https://www.linkedin.com/in/
sakazidevs'><i className='fa fa-linkedin'></i></Link>
   </div>
  </div>
</section>
  </>
  );
}
export default Contact;