import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import ErrorImage from '../media/errorImage.png';

const ErrorPage = () => {


  // code for metadata
  useEffect(() => {
    document.title = "Page is not found";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Page you are tying to reach is not accessible');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'error page, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []); 
  
  return (
    <div className="error-container">
      <img src={ErrorImage} alt='error-404' loading='lazy'/>
      <h1 className="error-title">404 - Page Not Found</h1><br />
      <Link to="/">Go to Homepage</Link>
    </div>
  );
};

export default ErrorPage;
