import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../contact.css';



function Quote() {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    serviceOptions: '',
    projectDetails: '',
    dateTime: '',
    budget: '',
    currency: 'KSH',
    file: [] 
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const unsupportedFiles = selectedFiles.filter(file => !isFileSupported(file.name));
    if (unsupportedFiles.length > 0) {
      toast.error('One or more selected files have unsupported formats. Supported formats are: .doc, .docx, .pdf, .jpg, .jpeg, .png, .JPG, .txt');
      e.target.value = null;
      return;
    }
    setFormData(prevState => ({
      ...prevState,
      file: selectedFiles
    }));
  };
  
  const isFileSupported = (fileName) => {
    const supportedFormats = ['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png', '.JPG', '.txt'];
    const fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
    return supportedFormats.includes(`.${fileExtension}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields for quote request
    if (!formData.firstName.trim() || !formData.lastName.trim() || !formData.email.trim() || !formData.phoneNumber.trim() || !formData.serviceOptions.trim() || !formData.projectDetails.trim() || !formData.budget.trim() || !formData.dateTime.trim()) {
      // If any required field is empty, prevent form submission and show an alert
      alert('Please fill out all required fields');
      return;
    }
    try {
      const formDataToSend = new FormData();
      // Append form data fields
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });
      // Append files
      formData.file.forEach(file => { // Change from "files" to "file"
        formDataToSend.append('file', file); // Change from "files" to "file"
      });

      await axios.post('https://server.sakazidevs.com/api/request-quote', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      toast.success('Quote request submitted successfully');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        serviceOptions: '',
        projectDetails: '',
        dateTime: '',
        budget: '',
        currency: 'KSH',
        file: []
      });
    } catch (error) {
      console.error('Error submitting quote request:', error);
      toast.error('Error submitting quote request');
    }
  };



// code to handle opening an functionalities of the schedule meeting form
const [isModalOpen, setIsModalOpen] = useState(false);
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phoneNumber, setPhoneNumber] = useState('');
const [date, setDate] = useState('');
const [time, setTime] = useState('');
const [disabledTimes, setDisabledTimes] = useState([]);
const [notification, setNotification] = useState('');

const timeOptions = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];

useEffect(() => {
  if (date !== '') {
    const currentDate = new Date().toISOString().split('T')[0];
    if (date < currentDate) {
      // Prevent selecting past dates
      setNotification('Please select a future date.');
      return;
    }

    fetch(`https://server.sakazidevs.com/get-disabled-times?date=${date}`)
      .then(response => response.json())
      .then(data => {
        setDisabledTimes(data);
        setNotification('');
      })
      .catch(error => console.error('Error fetching disabled times:', error));
  }
}, [date]);

const handleScheduleMeeting = () => {
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
  setNotification('');
};

const handleSubmitMeetingForm = (e) => {
  e.preventDefault();
  fetch('https://server.sakazidevs.com/schedule-meeting', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, email, phoneNumber, date, time }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        setNotification(data.error);
      } else if (data.message) {
        setNotification(data.message);
        setTimeout(() => {
          setName('');
          setEmail('');
          setPhoneNumber('');
          setDate('');
          setTime('');
          handleCloseModal(); 
        }, 3000);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};
// code to handle opening and functionalities of the schedule meeting form ends here


// code for metadata
useEffect(() => {
  document.title = "Request Quote";

  const metaDescription = document.createElement('meta');
  metaDescription.setAttribute('name', 'description');
  metaDescription.setAttribute('content', 'Lets talk about your project by filling up a form or scheduling a meeting');
  document.head.appendChild(metaDescription);


  const metaKeywords = document.createElement('meta');
  metaKeywords.setAttribute('name', 'keywords');
  metaKeywords.setAttribute('content', 'contact us, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
  document.head.appendChild(metaKeywords);


  return () => {
    document.title = "";

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  };
}, []);


  return (
  <>
<section className='top-header'>
<h2>Get a Quote</h2>
<p>We'll turn your dream into reality!</p>
{/* wavy svg */}
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280"><path fill="#F7F1ED" fill-opacity="1" d="M0,160L48,160C96,160,192,160,288,144C384,128,480,96,576,117.3C672,139,768,213,864,218.7C960,224,1056,160,1152,128C1248,96,1344,96,1392,96L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
</section>

{/* quote form */}
<section className='quote-container'>
<div className="form-container">
      <h1>Let's talk about your project</h1>
      <p>Drop us a line using this form, we'll get in touch shortly!</p><br /><br />
      <form onSubmit={handleSubmit}>
        <div className="form-group double">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
        </div>
        <div className="form-group double">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
            <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            required
          />
        </div>

        <div className="form-group">
          <select
            name="serviceOptions"
            value={formData.serviceOptions}
            onChange={handleChange}
            required
          >
            <option value="">Select Service</option>
            <option value="Web Design">Web Design</option>
            <option value="Graphic Design">Graphic Design</option>
            <option value="UI & UX Design">UI & UX Design</option>
            <option value="SEO">SEO</option>
            <option value="CMS">CMS</option>
            <option value="Consultancy">Consultancy</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <label>Tell us more about your project</label><br />
        <div className="form-group">
          <textarea
            name="projectDetails"
            value={formData.projectDetails}
            onChange={handleChange}
            placeholder="Type here ..."
            rows={8}
            required
          ></textarea>
        </div>
        <label>What is your budget?</label><br />
        <div className="form-group double">
          <input
            type="text"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            placeholder="0"
            required
          />
          <select
            name="currency"
            value={formData.currency}
            onChange={handleChange}
            className="currency-select"
          >
            <option value="KSH">KSH</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
        <label>When are you expecting the project to be completed?</label><br />
        <div className="form-group">
          <input
            type="datetime-local"
            name="dateTime"
            value={formData.dateTime}
            onChange={handleChange}
            min={new Date().toISOString().split('T')[0] + 'T00:00'}
            required
          />
        </div>
       <label>Do you have supporting files?</label><br />
        <div className="file-upload-container">
          <label htmlFor="fileInput" className="file-label">
            <span className="file-icon"></span>
            <span className="file-text">Drag & Drop files here</span>
            <span className="browse-link">or <u id="fileLabelText">click here</u> to browse</span>
          </label>
          <input
            type="file"
            id="fileInput"
            name="file"
            onChange={handleFileChange}
            accept=".doc, .docx, .pdf, .jpg, .jpeg, .png, .JPG, .txt"
            multiple
            style={{ display: 'none' }} 
          />
        {/* Display selected file names separated by commas */}
        <div className="selected-files-container">
            {formData.file.length > 0 && formData.file.map((file, index) => ( 
              <span key={index} className="selected-file">{file.name}{index !== formData.file.length - 1 ? ', ' : ''}</span> 
            ))}
          </div>
        </div>

        <button type="submit" className="submit-btn">SUBMIT</button>
      </form>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>

{/* text container */}
<div className='text-container'>
    <h2>What's next?</h2>
    <p>Next we’ll set up a video call where you can tell us more about your project, 
        get to know us, ask questions, get a price quote, and get a completion date</p>

        <div className='contact-details'>
            <h4>Contact info</h4>
            <ul>
                <li><i className='fa fa-phone'></i> +254 746092415</li>
                <li><i className='fa fa-phone'></i> +254 705554303</li><br />
                <li><i className='fa fa-envelope'></i> info@sakazidevs.com</li>
            </ul>
            <br/>

            <h4>Connect with us</h4>
            <ul className='social-links'>
          <Link to='https://facebook.com/sakazidevs'> <i className='fa fa-facebook'></i></Link>
          <Link to='https://twitter.com/sakazi_devs'><i className='fa fa-twitter'></i></Link>
          <Link to='https://github.com/sakazidevs'><i className='fa fa-github'></i></Link>
          <Link to='https://www.linkedin.com/in/
sakazidevs'><i className='fa fa-linkedin'></i></Link>
            </ul>
        </div><br />
      <button onClick={handleScheduleMeeting} className="schedule-btn">Schedule a Meeting</button>
</div>
</section>


{/* code to open the schedule meeting form */}
<div className="schedule-meeting-container">
      {isModalOpen && (
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <form onSubmit={handleSubmitMeetingForm}>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
              <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
              <input type="date" value={date} onChange={(e) => setDate(e.target.value)} min={new Date().toISOString().split('T')[0]} />
              <select value={time} onChange={(e) => setTime(e.target.value)}>
                <option value="">Select Time</option>
                {timeOptions.map(option => (
                  <option 
                    key={option} 
                    value={option} 
                    disabled={disabledTimes.includes(option)}
                    style={{ fontWeight: disabledTimes.includes(option) ? 'normal' : 'bold', color: disabledTimes.includes(option) ? '#999' : '#000' }}
                  >
                    {option}
                  </option>
                ))}
              </select>
              <button type="submit" className="submit-btn">Submit</button>
              {notification && <p className={notification.includes('successfully') ? 'success' : 'error'}>{notification}</p>}
            </form>
          </div>
        </div>
      )}
    </div>
  </>
  );
}
export default Quote;