import axios from 'axios';
import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import whyUsImage from '../media/why-choose-us-bg.jpg';


import { 
  faDesktop, 
  faPaintBrush, 
  faMobileAlt,
  faSearch,
  faLightbulb,
  faUsers,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons';
import '../About.css';



function About() {
  const [activeTab, setActiveTab] = useState('mission');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // code to animate typing words
  const [words, setWords] = useState(["excellence", "innovation", "integrity", "customer-centricity"]);
  const [index, setIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [speed, setSpeed] = useState(100);

  useEffect(() => {
    const typingEffect = () => {
      const currentLetter = isDeleting ? currentWord.length - 1 : currentWord.length + 1;
      setSpeed(currentLetter < 10 ? 50 : 100);

      setTimeout(() => {
        if (isDeleting) {
          setCurrentWord(currentWord.substring(0, currentLetter - 1));
        } else {
          setCurrentWord(words[index].substring(0, currentLetter));
        }
      }, speed);

      if (!isDeleting && currentWord === words[index]) {
        setTimeout(() => setIsDeleting(true), 500);
      } else if (isDeleting && currentWord === '') {
        setIsDeleting(false);
        setIndex((index + 1) % words.length);
      }
    };

    const typingInterval = setInterval(typingEffect, speed);

    return () => clearInterval(typingInterval);
  }, [currentWord, index, isDeleting, speed, words]);

  // code for ad section
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

// code for metadata
  useEffect(() => {
    document.title = "About Us";

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Sakazi Devs is your premier IT services provider in Kenya and beyond. Located in the heart of Nairobi, we are dedicated to revolutionizing the digital landscape by offering a comprehensive suite of services tailored to meet the unique needs of businesses across the region. From web design to consultancy, SEO optimization to graphic design, our expert team is committed to delivering innovative solutions that drive growth and success for our clients.');
    document.head.appendChild(metaDescription);


    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'about us, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
    document.head.appendChild(metaKeywords);


    return () => {
      document.title = "";

      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);



  // submitting review code
const [reviews, setReviews] = useState([]);
const [averageRating, setAverageRating] = useState(0);
const [showForm, setShowForm] = useState(false);

useEffect(() => {
  fetchReviews();
}, []);

const fetchReviews = async () => {
  try {
    const response = await axios.get('https://server.sakazidevs.com/api/reviews');
    const lastSixReviews = response.data.reviews.slice(-6); 
    setReviews(lastSixReviews);
    calculateAverageRating(lastSixReviews);
  } catch (error) {
    console.error('Error fetching reviews:', error);
  }
};

const calculateAverageRating = (reviewsData) => {
  if (reviewsData.length === 0) {
    setAverageRating(0);
    return;
  }

  const totalRating = reviewsData.reduce((acc, curr) => acc + curr.rating, 0);
  setAverageRating(totalRating / reviewsData.length);
};

const handleSubmit = async (e) => {
  e.preventDefault();
  const { name, rating, company, reviewText } = e.target.elements;
  if (name.value && rating.value && reviewText.value) {
    try {
      const response = await axios.post('https://server.sakazidevs.com/api/reviews', {
        name: name.value,
        rating: rating.value,
        company: company.value || '',
        reviewText: reviewText.value,
      });
      console.log(response.data.message);
      fetchReviews(); 
      // Clear form fields
      name.value = '';
      rating.value = '';
      company.value = '';
      reviewText.value = '';
      // Hide the form after submission
      setShowForm(false);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  } else {
    alert('Please enter name, rating, and review text');
  }
};

const renderStars = () => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(<span key={i} className={i < Math.round(averageRating) ? 'star-filled' : 'star-empty'}>&#9733;</span>);
  }
  return stars;
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 768, 
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};
//submitting review code ends here

  return (
    <>
    <div className="about-container">
        <div className="content">
          <h1>About us</h1>
          <p>
          Welcome to Sakazi Devs, your premier IT services provider in Kenya and beyond. Located in the heart of Nairobi, we are dedicated to revolutionizing the digital landscape by offering a comprehensive suite of services tailored to meet the unique needs of businesses across the region. From web design to consultancy, SEO optimization to graphic design, our expert team is committed to delivering innovative solutions that drive growth and success for our clients.
          </p>
          <div className="tabs">
            <button className={activeTab === 'vision' ? 'active' : ''} onClick={() => handleTabClick('vision')}>
              Vision
            </button>
            <button className={activeTab === 'mission' ? 'active' : ''} onClick={() => handleTabClick('mission')}>
              Mission
            </button>
            <button className={activeTab === 'coreValues' ? 'active' : ''} onClick={() => handleTabClick('coreValues')}>
              Core Values
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 'vision' && (
              <p>
                To be the leading provider of innovative IT services in Kenya, renowned for our commitment to quality, creativity, and client satisfaction. We aspire to be the partner of choice for businesses seeking to leverage technology to achieve their goals and surpass their expectations.
              </p>
            )}
            {activeTab === 'mission' && (
              <p>
                To empower Kenyan businesses and beyond with cutting-edge IT solutions that foster growth, efficiency, and success. We aim to be the catalyst for digital transformation, helping businesses thrive in an increasingly competitive marketplace.
              </p>
            )}
            {activeTab === 'coreValues' && (
              <p>
                <li><i class="fa fa-crosshairs"></i> Innovative</li>
                <li><i class="fa fa-crosshairs"></i> Reliable</li>
                <li><i class="fa fa-crosshairs"></i> Collaborative</li>
                <li><i class="fa fa-crosshairs"></i> Customer-satisfaction</li>
              </p>
            )}
          </div>
        </div>
    </div>

{/* second section */}
<div className='what-we-stand-for'>
<div className='box'><i class="fa fa-clock-o"></i> 24/7 Support</div>
<div className='box'><i class="fa fa-users"></i> Dedicated Team</div>
<div className='box'><i class="fa fa-bolt"></i>Reliable</div>
<div className='box'><i class="fa fa-times-circle"></i> No Hidden Cost</div>
</div>

{/* services section */}
<div className='our-services-heading'>
<h1>Our Services</h1>
</div>
<div className="about-services-container">
<div className="service">
  <FontAwesomeIcon icon={faDesktop} className="icon" />
  <h2>Web Design</h2>
  <p>Our expert designers craft visually stunning and user-friendly websites that captivate audiences and drive engagement.</p>
</div>

<div className="service">
  <FontAwesomeIcon icon={faPaintBrush} className="icon" />
  <h2>Graphic Design</h2>
  <p>From logos to branding materials, our creative team specializes in designing captivating visuals that leave a lasting impression.</p>
</div>

<div className="service">
  <FontAwesomeIcon icon={faMobileAlt} className="icon" />
  <h2>UI/UX Design</h2>
  <p>We create intuitive and seamless user experiences that enhance usability and maximize conversions across digital platforms.</p>
</div>

<div className="service">
  <FontAwesomeIcon icon={faSearch} className="icon" />
  <h2>SEO Optimization</h2>
  <p>Our SEO experts employ proven strategies to improve search engine rankings, boost online visibility, and drive organic traffic to your website.</p>
</div>

<div className="service">
  <FontAwesomeIcon icon={faLightbulb} className="icon" />
  <h2>Consultancy</h2>
  <p>With our in-depth industry knowledge and strategic insights, we provide expert consultancy services to help you navigate the complexities of the digital landscape and achieve your business objectives.</p>
</div>

<div className="service">
  <FontAwesomeIcon icon={faUsers} className="icon" />
  <h2>CMS Solutions</h2>
  <p>We offer robust content management solutions that empower you to easily manage and update your website content, ensuring flexibility and scalability.</p>
</div>
</div><br /><br />

<div className='browse-services'>
<Link to="/services">Learn more</Link>
</div>

{/* why choose us section */}
<div className="why-choose-us-container">
      <div className="about-us">
        <h2>Why Choose Us</h2>
        <p>At Sakazi Devs, we pride ourselves on our dedication to <span className="typing-effect">{currentWord}</span>. Through collaboration, creativity, and a relentless pursuit of results, we strive to exceed the expectations of our clients and contribute to the advancement of the digital landscape in Kenya and beyond.</p>
      </div>

      <div className="contact-details">
        <img src={whyUsImage} alt='why-us' loading='lazy'/>
      </div>
    </div>


 {/* Testimonials Section */}
 <div className="testimonial-container" id='reviews'>
      <h1>Latest Reviews</h1>
      <p>Average Rating: {averageRating.toFixed(1)}</p>
      <div className="stars">
        {renderStars()}
      </div>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <p><strong>{review.name}</strong></p>
            <p style={{ color: "#777" }}><i>{review.company}</i></p>
            <p><FontAwesomeIcon icon={faQuoteLeft} size="lg" style={{ color: "#777" }}/>    {review.reviewText}</p>
          </div>
        ))}
      </Slider><br />
      <p>What can you say about services offered by Sakazi Devs?</p>
      <button onClick={() => setShowForm(true)}>Submit Review</button>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Name" required /><br />
          <input type="text" name="company" placeholder="Company/Business (Optional)" /><br />
          <textarea name="reviewText" placeholder="Write your review..." required></textarea><br />
          <select name="rating" required>
            <option value="">Select Rating</option>
            {[1, 2, 3, 4, 5].map((rating) => (
              <option key={rating} value={rating}>{rating}</option>
            ))}
          </select><br />
          <button type="submit">Submit</button>
          <button type="button" onClick={() => setShowForm(false)}>Cancel</button>
        </form>
      )}
    </div>
<br/>


    {/* promoted ads */}
<div className={`ad-section ${isClosed ? 'closed' : ''}`}>
      <div className="ad-header">
        <p className="ad-text">Ad</p>
        <button className="close-button" onClick={handleClose}>X</button>
      </div>
      <div className="promo-content">
        <h2>Ready to Host Your Website?</h2>
        <p>Are you looking for <b>reliable</b>, <b>stable</b>, <b>affordable</b> place to get hosting or purchase your domain for your website or web application? Hostpinnacle gets you covered, starting from 3,499 KSH yearly. Use our <b>Promolink!</b></p>
        <Link to="https://www.hostpinnacle.co.ke/clients/aff.php?aff=2063" target="_blank" rel="noopener noreferrer" className="promo-button">Get Started</Link>
      </div>
    </div>
    </> 
  );
}

export default About;
