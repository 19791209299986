import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

const ScrollRevealComponent = ({ children, config }) => {
  useEffect(() => {
    ScrollReveal().reveal(children, config);
  }, []);

  return <div>{children}</div>;
};

export default ScrollRevealComponent;
